/* eslint-disable */
// imports the original component from vendor folder
import PicmentClickOutside from 'SourceComponent/ClickOutside/ClickOutside.component';


export class ClickOutside extends PicmentClickOutside { 
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }
}
export default ClickOutside;