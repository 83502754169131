/* eslint-disable */
import ColorSwatchPicker from '../component/ColorSwatchPicker';

const renderTextField = (args, callback, instance) => {
    const {
        option: {
            hks_swatch_identifier
        } = {}
    } = instance.props;

    if (hks_swatch_identifier === 'color') {
        return <ColorSwatchPicker { ...instance.props } />
    } else {
        return callback.apply(instance, args);
    }
}

export default {
    'Component/ProductCustomizableOption/Component': {
        'member-function': {
            renderTextField
        }
    }
}