/* eslint-disable */
import { getInitialState as sourceGetInitialState, SearchBarReducer as SourceSearchBarReducer } from 'SourceStore/SearchBar/SearchBar.reducer';
import { UPDATE_SEARCH_QUERY } from './SearchBar.action';
export * from 'SourceStore/SearchBar/SearchBar.reducer';

/** @namespace Store/SearchBar/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    searchQuery: ''
});

/** @namespace Store/SearchBar/Reducer */
export const SearchBarReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case UPDATE_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: action.query
            }
        default:
            return SourceSearchBarReducer(state, action);
    }
};

export default SearchBarReducer;