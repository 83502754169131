/* eslint-disable */
import Popup from 'Component/Popup';
import ImageZoomPopup from 'Component/ImageZoomPopup/ImageZoomPopup.component';

const render = (args, callback, instance) => {
    const {
        isActive,
        children,
        mix,
        popupId,
        onClose
    } = instance.props;

    if (!isActive) {
        return children;
    }

    return (
        <Popup
          id={ popupId }
          clickOutside={ false }
          mix={ { block: 'ImageZoomPopup', mix } }
          contentMix={ { block: 'ImageZoomPopup', elem: 'PopupContent' } }
          onClose={ onClose }
          onHide={ onClose }
        >
            <ImageZoomPopup
              { ...instance.props }
            />
        </Popup>
    );
}

const handleImageZoomPopupActiveChange = (args, callback, instance) => {
    const [isImageZoomPopupActive] = args;
    instance.setState(state => ({ ...state, isImageZoomPopupActive }));
}

export default {
    'Component/ImageZoomPopup/Container': {
        'member-function': {
            render
        }
    },
    'Component/ProductGallery/Container': {
        'member-function': {
            handleImageZoomPopupActiveChange
        }
    }
}