/* eslint-disable */
import { ADD_PAGE_TYPE, REMOVE_PAGE_TYPE } from './PageTypes.action';

/** @namespace Store/PageTypes/Reducer/getInitialState */
export const getInitialState = () => ({
    pageTypes: []
});

/** @namespace Store/PageTypes/Reducer */
export const PageTypesReducer = (
    state = getInitialState(),
    action
) => {
    const { pageType } = action;
    switch (action.type) {
        case ADD_PAGE_TYPE:
            return {
                ...state,
                pageTypes: [ ...state.pageTypes, pageType ]
            };
        case REMOVE_PAGE_TYPE:
            return {
                ...state,
                pageTypes: state.pageTypes.filter(pt => pt !== pageType)
            };
        default:
            return state;
    }
}