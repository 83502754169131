/* eslint-disable */
import {
    UPDATE_URL_REWRITE
} from 'Store/UrlRewrites/UrlRewrites.action';

const UrlRewritesReducer = (args, callback, instance) => {
    const [ _, action ] = args;

    if (action.type == UPDATE_URL_REWRITE) {
        const {
            urlRewrite
        } = action;
        window.urlRewrite = urlRewrite;
    }

    return callback.apply(instance, args);
}

export default {
    'Store/UrlRewrites/Reducer': {
        'function': UrlRewritesReducer
    }
}