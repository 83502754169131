/* eslint-disable */
import ClerkSearchWrapper from './ClerkSearchWrapper.component';

/** @namespace Component/ClerkSearchWrapper/Container */
export class ClerkSearchWrapperContainer extends PureComponent {
    state = {
        isActive: false
    }

    onClick() {
        this.setState(state => ({
            ...state,
            isActive: !state.isActive
        }));
    }

    onClickOutside() {
        this.setState(state => ({
            ...state,
            isActive: false
        }));
    }

    containerFunctions = {
        onClick: this.onClick.bind(this),
        onClickOutside: this.onClickOutside.bind(this)
    }

    render() {
        return (
            <ClerkSearchWrapper
                { ...this.state }
                { ...this.props }
                { ...this.containerFunctions }
            />
        );
    }
}

export default ClerkSearchWrapperContainer;