/* eslint-disable */
import { HeaderContainer as SourceHeaderContainer, mapStateToProps as sourceMapStateToProps, mapDispatchToProps } from 'SourceComponent/Header/Header.container';
import { connect } from 'react-redux';
import { SEARCH } from 'SourceComponent/Header/Header.config';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
export * from 'SourceComponent/Header/Header.container';

/** @namespace Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    navigationStateHistory: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationStateHistory,
    searchQuery: state.SearchBarReducer.searchQuery
});

/** @namespace Component/Header/Container */
export class HeaderContainer extends SourceHeaderContainer {
    componentDidMount() {
        super.componentDidMount();

        const {
            searchQuery
        } = this.props;

        if (searchQuery) {
            this.setState(state => ({
                ...state,
                searchCriteria: searchQuery
            }))
        }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        const {
            searchQuery
        } = this.props;

        const {
            searchQuery: prevSearchQuery
        } = prevProps;

        if (searchQuery !== prevSearchQuery) {
            this.setState(state => ({
                ...state,
                searchCriteria: searchQuery
            }))
        }
    }

    hideSearchOverlay() {
        const { hideActiveOverlay, activeOverlay } = this.props;

        // this.setState({ searchCriteria: '' });

        document.activeElement.blur();

        if (activeOverlay === SEARCH) {
            hideActiveOverlay();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);