/* eslint-disable */
import { lazy, Suspense } from 'react';
import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import Loader from 'Component/Loader';
const CustomerTypeForm = lazy(() => import('../component/CustomerTypeForm'));

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

const containerFunctions = (member, instance) => ({
    ...member,
    onCustomerTypeChange: instance.onCustomerTypeChange.bind(instance)
})

const onCustomerTypeChange = (args, callback, instance) => {
    const [e] = args;

    const {
        target: {
            name = ''
        } = {}
    } = e;

    const {
        changeCustomerTypeAndUpdateCartData
    } = instance.props;

    switch (name) {
        case 'retail':
            instance.setState(state => ({ ...state, isRetail: true, isLoading: true }), () => changeCustomerTypeAndUpdateCartData(name, () => instance.containerFunctions.setLoading(false)))
            break;
        case 'wholesale':
            instance.setState(state => ({ ...state, isRetail: false, isLoading: true }), () => changeCustomerTypeAndUpdateCartData(name, () => instance.containerFunctions.setLoading(false)))
        default:
            break;
    }
}

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        changeCustomerTypeAndUpdateCartData: (type, callback) => CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.changeCustomerTypeAndUpdateCartData(dispatch, type, callback)
        ),
        changeCustomerType: (type) => CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.changeCustomerType(dispatch, type)
        )
    };
}

const mapStateToProps = (args, callback, instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        customerTypeEnabled: state.ConfigReducer.customer_type_general_enabled,
        customerType: state.CartReducer.customerType
    };
}

const addCustomerTypeForm = (args, callback, instance) => {
    const {
        isRetail,
        onCustomerTypeChange,
        isLoading,
        customerTypeEnabled,
        checkoutStep
    } = instance.props;

    if (!customerTypeEnabled || checkoutStep !== SHIPPING_STEP) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            <Suspense fallback={ <Loader /> }>
                <CustomerTypeForm onCustomerTypeChange={ onCustomerTypeChange } isRetail={ isRetail } isLoading={ isLoading } />
            </Suspense>
        </>
    )
}

const __construct = (args, callback, instance) => {
    callback.apply(instance, args);

    const [props] = args;

    const {
        customerType,
        totals: {
            subtotal,
            subtotal_incl_tax
        }
    } = props;

    instance.state = {
        ...instance.state,
        isRetail: subtotal !== subtotal_incl_tax
    };
}

const componentDidMount = (args, callback, instance) => {
    callback.apply(instance, args);

    const { isRetail } = instance.state;
    const { changeCustomerType } = instance.props;

    if (!isRetail) {
        changeCustomerType(true);
    }
}

export default {
    'Route/Checkout/Container': {
        'member-property': {
            containerFunctions
        },
        'member-function': {
            onCustomerTypeChange,
            __construct,
            componentDidMount
        }
    },
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/Checkout/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/Checkout/Component': {
        'member-function': {
            'renderGuestForm': addCustomerTypeForm
        }
    }
}