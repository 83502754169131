/* eslint-disable */
import { withRouter } from 'react-router';
import ProductConfigurableAttributeDropdown from 'Component/ProductConfigurableAttributeDropdown';
import ProductAttributeValue from 'Component/ProductAttributeValue';

const ProductConfigurableAttributeDropdownWithRouter = withRouter(ProductConfigurableAttributeDropdown);
const ProductAttributeValueWithRouter = withRouter(ProductAttributeValue);

const renderDropdown = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    if (!result || result.type !== ProductConfigurableAttributeDropdown) {
        return result;
    }

    const {
        product
    } = instance.props;

    const updatedResult = React.createElement(ProductConfigurableAttributeDropdownWithRouter, { ...result.props, product });

    return updatedResult;
}

const renderConfigurableAttributeValue = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    const { product: { variants, ...rest } = {}, parameters } = instance.props;

    if (!result || !React.isValidElement(result) || !rest || result.type !== ProductAttributeValue) {
        return result;
    }

    const updatedResult = React.createElement(ProductAttributeValueWithRouter, { ...result.props, product: { ...rest }, parameters });

    return updatedResult;
}

const handleOptionClick = (args, callback, instance) => {
    const [ { attribute_code, attribute_value }, updateUrl ] = args;
    const { updateConfigurableVariant } = instance.props;
    updateConfigurableVariant(attribute_code, attribute_value, updateUrl);
}

export default {
    'Component/ProductConfigurableAttributes/Component': {
        'member-function': {
            renderDropdown,
            renderConfigurableAttributeValue
        }
    },
    'Component/ProductConfigurableAttributes/Container': {
        'member-function': {
            handleOptionClick
        }
    }
}