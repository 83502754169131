/* eslint-disable */
import { HeaderContainer as HoeksHeaderContainer, mapStateToProps as hoeksMapStateToProps, mapDispatchToProps as hoeksMapDispatchToProps } from 'HoeksComponent/Header/Header.container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { showNotification } from 'HoeksStore/Notification/Notification.action';
import Header from './Header.component';
import { makeCancelable } from 'Util/Promise';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
import { hash } from 'Util/Request/Hash';
import CategoryQuery from 'Query/Category.query';
import { IS_PRODUCT_PAGE, IS_CATEGORY_PAGE } from '../../plugin/PageTypeClass.plugin';

import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

export * from 'HoeksComponent/Header/Header.container';

/** @namespace Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...hoeksMapStateToProps(state),
    category: state.CategoryReducer.category,
    product: state.ProductReducer.product,
    pageTypes: state.PageTypesReducer.pageTypes,
    departmentMenu: state.ConfigReducer.department_menu,
    isDepartmentStore: state.ConfigReducer.is_department_store
})

/** @namespace Component/Header/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...hoeksMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message))
})

/** @namespace Component/Header/Container */
export class HeaderContainer extends HoeksHeaderContainer {
    dataModelName = 'DataContainer';

    state = {
        ...this.state,
        menuId: null,
        currentDepartment: null
    }

    componentWillUnmount() {
        this.cancelPromise();
    }

    fetchData(rawQueries, onSucces = () => {}, onError = () => {}) {
        const preparedQuery = prepareQuery(rawQueries);
        const { query, variables } = preparedQuery;
        const queryHash = hash(query + JSON.stringify(variables));

        if (!window.dataCache) {
            window.dataCache = {};
        }

        if (window.dataCache[queryHash]) {
            onSucces(window.dataCache[queryHash]);
            return;
        }

        this.promise = makeCancelable(
            executeGet(preparedQuery, this.dataModelName, ONE_MONTH_IN_SECONDS)
        );

        this.promise.promise.then(
            /** @namespace Util/Request/DataContainer/fetchData/thisPromisePromiseThen */
            (response) => {
                window.dataCache[queryHash] = response;
                onSucces(response);
            },
            /** @namespace Util/Request/DataContainer/fetchData/thisPromisePromiseCatch */
            (err) => onError(err)
        );
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps);

        const {
            category: {
                category_menu
            } = {},
            product: {
                sku
            },
            pageTypes
        } = this.props;

        const {
            currentDepartment: prevCurrentDepartment,
            currentDepartment: {
                name: prevName,
                url: prevUrl
            } = {}
        } = prevState;

        const {
            category: {
                category_menu: prevCategoryMenu
            } = {},
            product: {
                sku: prevSku
            },
            pageTypes: prevPageTypes
        } = prevProps;

        if (sku && ((sku !== prevSku) || pageTypes.includes(IS_PRODUCT_PAGE) && !prevPageTypes.includes(IS_PRODUCT_PAGE)) && (pageTypes.includes(IS_PRODUCT_PAGE) || pageTypes.length === 0)) {
            this.updateMenuFromProduct();
        } else if (category_menu && ((category_menu !== prevCategoryMenu) || (pageTypes.includes(IS_CATEGORY_PAGE) && !prevPageTypes.includes(IS_CATEGORY_PAGE))) && (pageTypes.includes(IS_CATEGORY_PAGE) || pageTypes.legnth === 0)) {
            // use new menu
            this.setState(state => ({
                ...state,
                menuId: category_menu
            }));
        }

        const currentDepartment = this.getCurrentDepartment(prevProps, prevState);

        const {
            name,
            url
        } = currentDepartment || {};


        if (name !== prevName || url !== prevUrl) {
            this.setState(state => ({
                ...state,
                currentDepartment
            }));
        }
    }

    updateMenuFromProduct() {
        const {
            product: {
                categories = []
            },
            history: {
                location: {
                    state: {
                        prevCategoryId
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!categories.length) {
            this.setState(state => ({
                ...state,
                menuId: null
            }));
        } else {
            const longestBreadcrumb = categories.reduce((acc, category) => {
                const { breadcrumbs } = category;
                const breadcrumbsLength = (breadcrumbs || []).length;

                if (acc.breadcrumbs === null) {
                    return category;
                }

                if (acc.breadcrumbs.length <= breadcrumbsLength) {
                    return category;
                }

                return acc;
            }, { breadcrumbs: null });
            const { id } = longestBreadcrumb;
            const finalId = prevCategoryId ?? id;

            this.cancelPromise();
            this.fetchData(
                [CategoryQuery.getQuery({ categoryIds: finalId })],
                data => {
                    const {
                        category: {
                            category_menu
                        } = {}
                    } = data;
                    this.setState(state => ({
                        ...state,
                        menuId: category_menu
                    }));
                },
                err => console.log(err)
            );
        }
    }

    getCurrentDepartment(prevProps, prevState) {
        const {
            pageTypes = [],
            product: {
                sku
            } = {}
        } = this.props;

        const {
            product: {
                sku: prevSku
            } = {}
        } = prevProps;

        const {
            currentDepartment
        } = this.state;

        const productDepartment = this.getCurrentDepartmentFromProduct();
        const categoryDepartment = this.getCurrentDepartmentFromCategory();

        if (pageTypes.includes(IS_PRODUCT_PAGE) && productDepartment && (sku !== prevSku)) {
            return productDepartment;
        }

        if (pageTypes.includes(IS_CATEGORY_PAGE) && categoryDepartment || (pageTypes.includes(IS_PRODUCT_PAGE) && !sku)) {
            return categoryDepartment;
        }

        return currentDepartment;

        if (productDepartment) {
            return productDepartment;
        }

        if (categoryDepartment) {
            return categoryDepartment;
        }

        return null;
    }

    getCurrentDepartmentFromProduct() {
        const {
            product: {
                categories = []
            } = {},
            history: {
                location: {
                    state: {
                        prevCategoryId
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!categories) {
            return null;
        }

        const longestBreadcrumb = categories.reduce((acc, category) => {
            const { breadcrumbs } = category;
            const breadcrumbsLength = (breadcrumbs || []).length;

            if (acc.breadcrumbs === null) {
                return category;
            }

            if (acc.breadcrumbs.length <= breadcrumbsLength) {
                return category;
            }

            return acc;
        }, { breadcrumbs: null });

        const { id: longestBreadcrumbId, breadcrumbs: longestBreadcrumbs = [] } = longestBreadcrumb;
        const validCategories = longestBreadcrumbs === null ? [ longestBreadcrumbId ] : [ longestBreadcrumbId, ...longestBreadcrumbs.map(({ category_id }) => category_id) ];

        const bestCategory = categories.filter(({ id }) => prevCategoryId || validCategories.includes(id)).reduce((acc, category) => {
            const { breadcrumbs: categoryBreadcrumbs, id } = category;
            const { bestFound } = acc;

            if (bestFound) {
                return acc;
            }

            if (id === prevCategoryId && categoryBreadcrumbs === null) {
                return { category, bestFound: true };
            }

            if (id === prevCategoryId && categoryBreadcrumbs !== null) {
                const bestBreadcrumb = categoryBreadcrumbs.find(({ category_level }) => id === prevCategoryId && category_level === 2);
                if (bestBreadcrumb) {
                    const { category_name, category_url } = bestBreadcrumb;
                    return { name: category_name, url: category_url, bestFound: true };
                }
            }

            if (categoryBreadcrumbs === null) {
                return category;
            }

            return acc;

        }, { breadcrumbs: null });

        const { name, url } = bestCategory;

        if (name && url) {
            return { name, url };
        }

        return null;
    }

    getCurrentDepartmentFromCategory() {
        const {
            category: {
                breadcrumbs = [],
                name,
                url
            } = {}
        } = this.props;

        if (!breadcrumbs && name && url) {
            return { name, url };
        }

        if (!breadcrumbs) {
            return null;
        }

        const bestBreadcrumb = breadcrumbs.reduce((acc, breadcrumb) => {
            const { category_level } = breadcrumb;

            if (category_level === 2) {
                return breadcrumb;
            }

            return acc;
        }, {});

        const { category_name, category_url } = bestBreadcrumb;

        if (category_name && category_url) {
            return { name: category_name, url: category_url };
        }

        return null;
    }

    containerFunctions = {
        ...this.containerFunctions,
        showNotification: this.props.showNotification
    }

    updatedContainerProps = () => {
        const {
            category,
            product,
            pageTypes,
            departmentMenu,
            isDepartmentStore
        } = this.props;

        return {
            ...this.containerProps(),
            category,
            product,
            pageTypes,
            // currentDepartment: this.getCurrentDepartment(),
            departmentMenu,
            isDepartmentStore
        };
    }

    cancelPromise() {
        if (this.promise) {
            this.promise.cancel();
        }
    }

    render() {
        return (
            <Header
              { ...this.state }
              { ...this.updatedContainerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));