/* eslint-disable */

import { lazy } from 'react';
export const TrustpilotWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "trustpilot" */ '../component/TrustpilotWidget'));

const TRUSTPILOT = 'Trustpilot';
const addTrustpilotWidget = (member, instance) => {

    return {
        ...member,
        [TRUSTPILOT]: {
            component: TrustpilotWidget
        }
    }
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': addTrustpilotWidget
        }
    },
}