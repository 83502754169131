/* eslint-disable */

import { MenuItem as SourceMenuItem } from 'SourceComponent/MenuItem/MenuItem.component';
import { Link } from 'SourceComponent/Link/Link.component';

export class MenuItem extends SourceMenuItem {
    renderItemLinkContent() {
        const {
            activeMenuItemsStack,
            item,
            itemMods,
            handleCategoryHover,
            onItemClick
        } = this.props;

        const {
            url,
            item_id,
            item_class
        } = item;

        const isHovered = activeMenuItemsStack.includes(item_id);

        return (
            <Link
              to={ url }
              block="Menu"
              elem="Link"
              id={ item_id }
              className={ item_class }
              onMouseEnter={ handleCategoryHover }
              mods={ { isHovered } }
              onClick={ onItemClick }
            >
                { this.renderItemContent(item, itemMods) }
            </Link> 
        );
    }
}

export default MenuItem;