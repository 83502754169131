/* eslint-disable */
import { callApi } from '../util/Clerk/Api';

const clickTrack = (args, callback, instance) => {
    const { product: { id }, isClerkProduct = false } = instance.props;
    if (isClerkProduct){
        callApi(
            'log/click',
            { product: id},
            function(response){},
            console.log
        );
    }
    return callback(...args);
}

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            registerSharedElement: clickTrack
        }
    }
}
