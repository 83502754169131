/* eslint-disable */
import { ColorSwatchReducer } from '../store/ColorSwatch/ColorSwatch.reducer';

const getStaticReducers = (args, callback, instance) => ({
    ...callback(...args),
    ColorSwatchReducer
});

export const config = {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};

export default config;