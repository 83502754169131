/* eslint-disable */
export * from 'HoeksUtil/Product/Product';
import {
    getIndexedVariants,
    getIndexedAttributes,
    getIndexedReviews,
    getIndexedConfigurableOptions,
    getIndexedCustomOptions
} from 'HoeksUtil/Product/Product';

/** @namespace Util/Product/getIndexedSingleVariant */
export const getIndexedSingleVariant = (variants, itemSku) => {
    const index = variants.findIndex(({ product: { sku } }) => sku === itemSku || itemSku.includes(sku));

    if (index < 0) {
        return getIndexedVariants(variants);
    }

    const indexedProduct = variants[index].product;
    const { attributes } = indexedProduct;

    return [
        { ...indexedProduct, attributes: getIndexedAttributes(attributes || []) }
    ];
};

/** @namespace Util/Product/getIndexedProduct */
export const getIndexedProduct = (product, itemSku) => {
    const {
        variants: initialVariants = [],
        configurable_options: initialConfigurableOptions = [],
        attributes: initialAttributes = [],
        options: initialOptions = [],
        rating_summary,
        review_count,
        reviews: initialReviews
    } = product;

    const attributes = getIndexedAttributes(initialAttributes || []);
    const reviews = getIndexedReviews(initialReviews);

    return {
        ...product,
        configurable_options: getIndexedConfigurableOptions(initialConfigurableOptions, attributes),
        variants: itemSku ? getIndexedSingleVariant(initialVariants, itemSku) : getIndexedVariants(initialVariants),
        options: getIndexedCustomOptions(initialOptions || []),
        attributes,
        // Magento 2.4.1 review endpoint compatibility
        reviews,
        review_summary: {
            rating_summary,
            review_count
        }
    };
};

/** @namespace Util/Product/checkEveryOption */
export const checkEveryOption = (attributes, options) => Object.keys(options)
    .every((option) => {
        if (!attributes[option]) {
            return false;
        }

        const { attribute_value } = attributes[option];
        if (typeof options[option] === 'string') {
            if (typeof attribute_value === 'string' && attribute_value.includes('.')) {
                return options[option] === attribute_value.split(".")[0];
            } else {
                return options[option] === attribute_value;
            }
        }

        if (typeof attribute_value === 'string' && attribute_value.includes('.')) {
            return options[option].includes(attribute_value.split(".")[0]);
        } else {
            return options[option].includes(attribute_value);
        }
    });

    /** @namespace Util/Product/getVariantIndex */
export const getVariantIndex = (variants, options) => variants
    .findIndex((variant) => checkEveryOption(variant.attributes, options));

/** @namespace Util/Product/getIndexedProducts */
export const getIndexedProducts = (products) => products.map((product) => getIndexedProduct(product));