/* eslint-disable */

// imports the original component from vendor folder
import HoeksNotification from 'HoeksComponent/Notification/Notification.component';

// import dependencies 
import ContentWrapper from 'Component/ContentWrapper';

// import new extended styles file
import './Notification.override.style.scss';

export class Notification extends HoeksNotification { 

}

export default Notification;
