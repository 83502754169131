/* eslint-disable */
import ProductList from 'Component/ProductList/ProductList.component';
import ProductListPage from 'Component/ProductListPage';

const getProductListContainerMemberFunctions = () => {
    const _getIsInfiniteLoaderEnabled = (args, callback, instance) => {
        const { isInfiniteLoaderEnabled } = instance.props;
        return isInfiniteLoaderEnabled
    }

    const render = (args, callback, instance) => {
        return (
            <ProductList
              { ...instance.props }
              { ...instance.containerFunctions }
              { ...instance.containerProps() }
              { ...instance.state }
            />
        );
    }

    const componentDidMount = (args, callback, instance) => {
        const { pages, isPreventRequest, search } = instance.props;
        const { pagesCount } = instance.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength && pagesLength !== 0 && search && window.lastSearch) {
            instance.setState({ pagesCount: pagesLength });
        }

        if (!search) {
            delete window.lastSearch;
        }

        // Is true when category is changed. This check prevents making new requests when navigating back to PLP from PDP
        if (!isPreventRequest) {
            instance.requestPage(instance._getPageFromUrl());
        }
    }

    const componentDidUpdate = (args, callback, instance) => {
        const [ prevProps ] = args;
        const { sort, search, filter, filter: { categoryIds = null }, sort: { sortDirection, sortKey } = {} } = instance.props;
        const { sort: prevSort, search: prevSearch, filter: prevFilter, filter: { categoryIds: prevCategoryIds = null }, sort: { sortDirection: prevSortDirection, sortKey: prevSortKey } = {} } = prevProps;

        if (search !== prevSearch || prevCategoryIds !== categoryIds || (prevCategoryIds && search) || (sortDirection !== prevSortDirection) || (sortKey !== prevSortKey)) {
            instance.setState(state => ({
                ...state,
                pagesCount: 1
            }))
        }

        if (search !== prevSearch
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || JSON.stringify(filter) !== JSON.stringify(prevFilter)
        ) {
            instance.requestPage(instance._getPageFromUrl());
        }
    }

    const loadPage = (args, callback, instance) => {
        const [ next = true ] = args;
        const { pagesCount } = instance.state;
        const { isPageLoading } = instance.props;

        const {
            minPage,
            maxPage,
            totalPages,
            loadedPagesCount
        } = instance._getPagesBounds();

        const isUpdatable = totalPages > 0 && pagesCount === loadedPagesCount;
        const shouldUpdateList = next ? maxPage < totalPages : minPage > 1;

        if (isUpdatable && shouldUpdateList && !isPageLoading) {
            instance.setState(state => ({ ...state, pagesCount: state.pagesCount + 1 }));
            instance.requestPage(next ? maxPage + 1 : minPage - 1, true);
        }
    }

    return {
        _getIsInfiniteLoaderEnabled,
        render,
        componentDidMount,
        componentDidUpdate,
        loadPage
    }
}

const getProductListComponentMemberFunctions = () => {
    const componentDidUpdate = (args, callback, instance) => {
        const [ prevProps ] = args;
        const { isWidget, currentPage, isInfiniteLoaderEnabled } = instance.props;
        const { currentPage: prevCurrentPage } = prevProps;

        // Scroll up on page change, ignore widgets
        if (prevCurrentPage !== currentPage && !isWidget && !isInfiniteLoaderEnabled) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        if (isInfiniteLoaderEnabled) {
            instance.observePageChange();
        }
    }

    const renderPagination = (args, callback, instance) => {
        const {
            isInfiniteLoaderEnabled,
            loadPage,
            totalPages,
            pages
        } = instance.props;

        const lastPage = Math.max(...Object.keys(pages));

        if (lastPage === totalPages) {
            return null;
        }

        if (isInfiniteLoaderEnabled) {
            return (
                <div
                    block="ProductList"
                    elem="LoadButtonContainer"
                    >
                    <div
                        block="ProductList"
                        elem="LoadButton"
                        mix={{ block: 'Button'}}
                        role="button"
                        tabIndex="0"
                        onKeyUp={ loadPage }
                        onClick={ loadPage }
                    >
                        { __('Load more') }
                    </div>
                </div>  
            )
        }

        return callback.apply(instance, args);
    }

    const renderPages = (args, callback, instance) => {
        const {
            pages,
            isVisible,
            isLoading,
            isInfiniteLoaderEnabled,
            pagesCount
        } = instance.props;

        if (isLoading) {
            return instance.renderPage();
        }

        const pageRenders = Object.entries(pages).map(instance.renderProductPage);

        if (isVisible && isInfiniteLoaderEnabled && Object.keys(pages).length < pagesCount) { // add placeholders to the end of pages if needed
            const key = Math.max(...Object.keys(pages)) + 1; // the key should match next page key
            pageRenders.push(instance.renderPage({ key }));
        }

        return pageRenders;
    }

    return {
        componentDidUpdate,
        renderPagination,
        renderPages
    }
}

const getProductListPageComponentMemberFunctions = () => {
    const startObserving = () => null

    return {
        startObserving
    }
}

export default {
    'Component/ProductList/Container': {
        'member-function': getProductListContainerMemberFunctions()
    },
    'Component/ProductList/Component': {
        'member-function': getProductListComponentMemberFunctions()
    },
    'Component/ProductListPage/Component': {
        'member-function': getProductListPageComponentMemberFunctions()
    }
}