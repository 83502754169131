/* eslint-disable */
import { Header as SourceHeader } from 'SourceComponent/Header/Header.component';
import {
    CART_OVERLAY,
    CUSTOMER_WISHLIST
} from 'Component/Header/Header.config';
import NavigationTabs from 'Component/NavigationTabs';
import './Header.base.override.style.scss';
export * from 'SourceComponent/Header/Header.component';

/** @namespace Component/Header/Component */
export class Header extends SourceHeader {
    renderTopMenu() {
        return (
            <>
                { super.renderTopMenu() }
                <NavigationTabs />
            </>
        )
    }

    renderComparePageButton() {
        return (
         <></>
        );
    }

    renderTitle(isVisible = false) {
        return null;
    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device
        } = this.props;

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        return (
                <section block="Header" elem="Wrapper" mods={ { name, isCheckout } }>
                    <header
                      block="Header"
                      mods={ { name, isHiddenOnMobile, isCheckout } }
                      mix={ { block: 'FixedElement', elem: 'Top' } }
                      ref={ this.logoRef }
                    >
                        { this.renderTopMenu() }
                        <nav block="Header" elem="Nav">
                            { this.renderNavigationState() }
                        </nav>
                        { this.renderMenu() }
                    </header>
                </section>
        );
    }
}

export default Header;