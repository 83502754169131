/* eslint-disable */

// imports the original component from vendor folder
import HoeksPopup from 'HoeksComponent/Popup/Popup.component';

// import new extended styles file
import './Popup.override.style.scss';

import { ESCAPE_KEY } from 'Component/Popup/Popup.config';
import history from 'Util/History';

/** @namespace Component/Popup/Component */
export class Popup extends HoeksPopup {
    hidePopupAndGoBack = () => {
        this.hidePopUp();
        history.goBack();
    };

    // Same with click outside
    handleClickOutside = () => {
        const { clickOutside } = this.props;
        if (!clickOutside) {
            return;
        }
        this.hidePopupAndGoBack();
    };

    handleKeyDown = (e) => {
        if (!this.getIsVisible()) {
            this.hidePopUp();
            return;
        }

        switch (e.keyCode) {
        case ESCAPE_KEY:
            this.hidePopupAndGoBack();
            break;
        default:
            break;
        }
    };

    renderCloseButton() {
        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label={ __('Close') }
              onClick={ this.hidePopupAndGoBack }
            />
        );
    }
}

export default Popup;
