/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const PostsDetails = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "blog" */ '../route/PostsDetails'));
export const PostsListing = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "blog" */ '../route/PostsListing'));

export class RouterPlugin {
  /**
   * Adds blog url handles to the router SWITCH_ITEMS_TYPE list
   * @param originalMember
   * @returns {*[]}
   */
  switchItems = (originalMember) => [
    ...originalMember,
    {
      component: <Route path="/blog/:handle" component={PostsDetails} />,
      position: 100,
    },
    {
      component: <Route path="/blog" component={PostsListing} />,
      position: 110,
    },
  ];
}

const { switchItems } = new RouterPlugin();

export const config = {
  'Component/Router/Component': {
    'member-property': {
      SWITCH_ITEMS_TYPE: switchItems,
    },
  },
};

export default config;
