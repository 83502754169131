import ProductLinks from 'Component/ProductLinks/ProductLinks.component';
import ContentWrapper from 'Component/ContentWrapper';
import Slider from '@hoeks/slider-widget/src/component/DraggableSlider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Clerk.style';

/** @namespace Hoeks/Clerk/Component/Clerk/Component/ClerkComponent */
export class ClerkComponent extends ProductLinks {

    
    renderHeading() {
        const { title } = this.props;
        return (
            <h3 block="Clerk" elem="Title">
                { title }
            </h3>
        );
    }

    _sliderSettings(){
        const { slidesToShow } = this.props;
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: slidesToShow,
            slidesToScroll: slidesToShow,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }             
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }             
                }
            ]
        };

        return settings;
    }

    render() {
        const settings = this._sliderSettings();

        return (
            <ContentWrapper
              mix={ { block: 'Clerk' } }
              wrapperMix={ { block: 'Clerk', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                <ul block="Clerk" elem="Slider">
                <Slider {...settings}>
                    { this.renderItems() }
                </Slider>
                </ul>
            </ContentWrapper>
        );
    }
}

export default ClerkComponent;
