/* eslint-disable */
import { CartQuery as HoeksCartQuery } from 'HoeksQuery/Cart.query';

import ProductListQuery from 'Query/ProductList.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Query/Cart */
export class CartQuery extends HoeksCartQuery {

    _getProductField() {
        ProductListQuery.options.isForLinkedProducts = true;

        const productQuery = new Field('product')
            .addField(ProductListQuery._getCategoriesField())
            .addFieldList(ProductListQuery._getProductInterfaceFields(false, true));

        ProductListQuery.options.isForLinkedProducts = false;

        return productQuery;
    }
}

export default new CartQuery();
