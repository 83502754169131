/* eslint-disable */
import { lazy, Suspense } from 'react';
import ClerkPlaceholder from '../component/ClerkPlaceholder';
export const ClerkWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "clerk" */ '../component/ClerkWidget'));

export const CLERK_TYPE = 'Clerk';

class LazyClerkWidget extends PureComponent {
    render() {
        return (
            <Suspense fallback={ <ClerkPlaceholder /> }>
                <ClerkWidget { ...this.props } />
            </Suspense>
        )
    }
}

const addClerkWidget = (member, instance) => {
    return {
        ...member,
        [CLERK_TYPE]: {
            component: LazyClerkWidget,
            fallback: () => <ClerkPlaceholder />
        }
    }
}

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': addClerkWidget
        }
    },
}