/* eslint-disable */

const _getCustomizableProductFragmentOptionsFields = (args, callback, instance) => ([
    ...callback.apply(instance, args),
    'hks_swatch_identifier'
])

export default {
    'Query/ProductList': {
        'member-function': {
            _getCustomizableProductFragmentOptionsFields
        }
    }
}