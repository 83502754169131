/* eslint-disable */

export const _getProductInterfaceFields = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        'hoeks_offer_sample',
        'hoeks_is_sample_product',
        'hoeks_sample_label',
        'hoeks_sample_product_sku',
        'hoeks_sample_description'
    ]
}

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields
        }
    }
}