/* eslint-disable */

// imports the original component from vendor folder
import HoeksNewVersionPopup from 'HoeksComponent/NewVersionPopup/NewVersionPopup.component';

// import new extended styles file
import './NewVersionPopup.override.style.scss';

export class NewVersionPopup extends HoeksNewVersionPopup { 

}

export default NewVersionPopup;
