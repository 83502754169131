/* eslint-disable */
import CmsImage from 'Component/CmsImage';

const replaceImages = (args, callback, instance) => {
    const result = callback(...args);
    return <CmsImage {...result.props} cms/>;
}

export default {
    'Component/Html/Component': {
        'member-function': {
            replaceImages
        }
    }
}
