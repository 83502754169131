export const validateEanNumer = (value) => {
    return value.trim().length === 13;
}

const addEanValidation = (args, callback, instance) => {
    return {
        ...callback(...args),
        eanNumber: {
            validate: validateEanNumer,
            message: __('Ean Number is invalid.')
        }
    }
}

export default {
    'Component/Form/Config': {
        'function': addEanValidation
    }
};