/* eslint-disable */

// imports the original component from vendor folder
import SourceBreadcrumb from 'SourceComponent/Breadcrumb/Breadcrumb.component';

// import new extended styles file
import './Breadcrumb.override.style.scss';

export class Breadcrumb extends SourceBreadcrumb { 

}

export default Breadcrumb;
