import BrowserDatabase from 'Util/BrowserDatabase';
export const VISITORID_CONFIG = 'clerk_visitor_id';
export const KEY_CONFIG = 'clerk_store_id';
export const API_URL = 'https://api.clerk.io/v2/';

export const generate_token = (length) => {
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];  
    for (var i=0; i<length; i++) {
        var j = (Math.random() * (a.length-1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
}

export const getVisitorId = () => {
    const id = BrowserDatabase.getItem(VISITORID_CONFIG);
    if (!id){
        const newId = generate_token(8);
        BrowserDatabase.setItem(newId, VISITORID_CONFIG);
        return newId;
    }
    return id;
}

export const getStoreKey = () => BrowserDatabase.getItem(KEY_CONFIG);

export const callApi = (requestPath, data, callback, errorCallback, apikey = null) => {
    const payloadObject = {...data, visitor: getVisitorId(), key: apikey || getStoreKey(), _: new Date().getTime() }
    const payload = `?payload=${encodeURIComponent(JSON.stringify(payloadObject))}`;
    fetch(API_URL+requestPath+payload)
      .then(res => res.json())
      .then(
        callback,
        errorCallback
      );
}


