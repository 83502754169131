/* eslint-disable */

const getImageUrl = (args, callback, instance) => {
    const {
        gallery: [
            {
                thumbnail: {
                    url = ''
                } = {}
            } = {}
        ] = []
    } = instance.props;

    return url;
}

export default {
    'Component/ProductGallery/Component': {
        'member-function': {
            getImageUrl
        }
    }
}