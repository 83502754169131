const aroundAfterAddToCart = (args, callback, instance) => {
    const { product: { id } } = instance.props;
    if (window.Clerk){
        window.Clerk('cart', 'add', id);
    }
    return callback(...args);
}

const handleRemoveItem = (args, callback, instance) => {
    callback(...args);
    const { item: { product: { id } } } = instance.props;
    if (window.Clerk){
        window.Clerk('cart', 'remove', id);
    }
};
const cartSet = (args, callback, instance) => {
    const [data] = args;
    const { cartData: { items }} = data;
    if (window.Clerk){
        const cartItems = items.map(item => item.product.id);
        window.Clerk('cart', 'set', cartItems);
    }
    return callback(...args);
}

export default {
    'Component/AddToCart/Container': {
        'member-function': {
            'afterAddToCart': aroundAfterAddToCart
        }
    },
    'Component/CartItem/Container': {
        'member-function': {
            'handleRemoveItem': handleRemoveItem
        }
    },
    'Store/Cart/Dispatcher/_syncCartWithBEFetchQueryThen': {
        'function': cartSet
    }
};