import { connect } from 'react-redux';

import TrustpilotScript from './TrustpilotScript.component';

const mapStateToProps = state => ({
    enabled: state.ConfigReducer.trustpilot.enabled,
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(TrustpilotScript);
