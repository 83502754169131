/* eslint-disable */
import {
    convertQueryStringToKeyValuePairs
} from 'Util/Url';
import { CONFIGURABLE } from 'Util/Product';

const variantSkuFilter = (originalSKU) => ({ sku: variantSku }) => variantSku === originalSKU;

const getDerivedStateFromProps = (args, callback) => {
    const [ props, state ] = args;
    const result = callback.apply(null, args);

    const {
        product: {
            sku,
            variants,
            configurable_options,
            url
        },
        originalSKU,
        location: {
            search,
            pathname
        }
    } = props;

    const {
        currentProductSKU: prevSKU
    } = state;

    if (
        configurable_options &&
        sku !== originalSKU &&
        pathname !== url &&
        variants.some(variantSkuFilter(originalSKU))
    ) {
        const queryConfigurableAttributes = Object.entries(convertQueryStringToKeyValuePairs(search)).filter(([key]) => key in configurable_options);
        const configurableVariantIndex = variants.findIndex(variantSkuFilter(originalSKU));
        const {
            attributes: variantAttributes
        } = variants[configurableVariantIndex];

        const parameters = Object.entries(variantAttributes).reduce((acc, [key, value]) => {
            const {
                attribute_value
            } = value;
            if (key in configurable_options) {
                return { ...acc, [key]: attribute_value };
            }
            return acc;
        }, {});

        if (queryConfigurableAttributes.length <= 0) {
            return {
                ...result,
                parameters,
                configurableVariantIndex
            }
        } else {
            const mergedParameters = queryConfigurableAttributes.reduce((acc, [key, value]) => {
                return { ...acc, [key]: value };
            }, parameters);
            const matchingConfigurableVariantIndex = variants.findIndex(variant => {
                const {
                    attributes
                } = variant;
                const attributesArr = Object.entries(attributes);
                const mergedParametersArr = Object.entries(mergedParameters);
                return mergedParametersArr.every(([parameterKey, parameterValue]) => attributesArr.some(([attributeKey, { attribute_value: attributeValue }]) => parameterKey === attributeKey && parameterValue === attributeValue));
            });

            if (matchingConfigurableVariantIndex < 0) {
                return result;
            }

            return {
                ...result,
                parameters: mergedParameters,
                configurableVariantIndex: matchingConfigurableVariantIndex
            }
        }
    }

    const currentProductSKU = prevSKU === sku ? '' : prevSKU;

    /**
     * If the product we expect to load is loaded -
     * reset expected SKU
     */
    if (!configurable_options && !variants) {
        return {
            currentProductSKU
        };
    }

    const parameters = Object.entries(convertQueryStringToKeyValuePairs(search))
        .reduce((acc, [key, value]) => {
            if (key in configurable_options) {
                return { ...acc, [key]: value };
            }

            return acc;
        }, {});

    if (Object.keys(parameters).length !== Object.keys(configurable_options).length) {
        return {
            parameters,
            currentProductSKU,
            configurableVariantIndex: -1
        };
    }

    return result;
}

const updateBreadcrumbs = (args, callback, instance) => {
    const { updateBreadcrumbs, location } = instance.props;
    const { state: { prevCategoryId = null } = {} } = location;
    const {
        type_id,
        variants,
        categories
    } = instance.getDataSource();

    const {
        configurableVariantIndex
    } = instance.state;

    if (type_id === CONFIGURABLE && configurableVariantIndex >= 0 && (!categories || !categories.length)) {
        updateBreadcrumbs(variants[configurableVariantIndex], prevCategoryId);
        return;
    }

    return callback.apply(instance, args);
}

const componentDidUpdate = (args, callback, instance) => {
    const [_, prevState] = args;

    const {
        configurableVariantIndex
    } = instance.state;

    const {
        configurableVariantIndex: prevConfigurableVariantIndex
    } = prevState;

    if ((configurableVariantIndex !== prevConfigurableVariantIndex) && configurableVariantIndex >= 0) {
        instance.updateBreadcrumbs();
    }

    return callback.apply(instance, args);
}

const getConfigurableVariantIndex = (args, callback, instance) => {
    const { configurableVariantIndex } = instance.state;
    return configurableVariantIndex;
}

const updateConfigurableVariant = (args, callback, instance) => {
    const [ key, value, updateUrl = false ] = args;

    if (!updateUrl) {
        return callback.apply(instance, args);
    }

    const parameters = instance.getNewParameters(key, value);
    instance.setState({ parameters });

    const { history } = instance.props;

    history.replace(updateUrl);
    instance.updateConfigurableVariantIndex(parameters);
}

export default {
    'Route/ProductPage/Container': {
        'static-member': {
            getDerivedStateFromProps
        },
        'member-function': {
            updateBreadcrumbs,
            componentDidUpdate,
            getConfigurableVariantIndex,
            updateConfigurableVariant
        }
    }
}