/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import CategoriesReducer from '../store/Categories/Categories.reducer';
import PostsReducer from '../store/Posts/Posts.reducer';
import PostsDetailsReducer from '../store/PostsDetails/PostsDetails.reducer';
import PostsSearchReducer from '../store/PostsSearch/PostsSearch.reducer';

export class StoreIndexPlugin {
  /**
   * Plugin to add blog reducers to getStaticReducers method.
   * @param args
   */
  aroundGetStaticReducers = (args, callback) => ({
    ...callback(...args),
    CategoriesReducer,
    PostsDetailsReducer,
    PostsReducer,
    PostsSearchReducer,
  });
}

const { aroundGetStaticReducers } = new StoreIndexPlugin();

export const config = {
  'Store/Index/getReducers': {
    function: aroundGetStaticReducers,
  },
};

export default config;
