/* eslint-disable */
import { formConfig as SourceFormConfig, validateTelephone} from 'SourceComponent/Form/Form.config';
export * from 'SourceComponent/Form/Form.config';

const validateDkNumber = ({ value }) => {
    return value.match(/^[2-9][0-9]{7}$/) || validateTelephone({ value });
};

/** @namespace Component/Form/Config */
export const formConfig = () => ({
    ...SourceFormConfig(),
    telephone: {
        validate: validateDkNumber,
        message: __('Phone number is invalid!')
    }
});

export default formConfig();