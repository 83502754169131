/* eslint-disable */
import SourceField from 'SourceComponent/Field/Field.component';

export class Field extends SourceField {
    render() {
        const {
            mix,
            type,
            message,
            validationStatus,
            id
        } = this.props;

        return (
            <div
              block="Field"
              mods={ {
                  type,
                  hasError: validationStatus === false || !!message,
                  isValid: validationStatus === true,
                  name: id
              } }
              mix={ mix }
            >
                { this.renderLabel() }
                { this.renderInputOfType(type) }
                { this.renderMessage() }
            </div>
        );
    }
}

export default Field;