/* eslint-disable */

const renderSearchField = (args, callback, instance) => {
    return null;
};

export default {
    'Component/Header/Component': {
        'member-function': {
            'renderSearchField': {
                implementation: renderSearchField,
                position: 110
            }
        }
    },
    'Component/MenuOverlay/Component': {
        'member-function': {
            'renderSearchField': {
                implementation: renderSearchField,
                position: 110
            }
        }
    }
}