/* eslint-disable */

// imports the original component from vendor folder
import SourceFooter from 'SourceComponent/Footer/Footer.component';

import CmsBlock from 'Component/CmsBlock';

// import new extended styles file
import './Footer.override.style.scss';

export class Footer extends SourceFooter { 

    renderCmsBlockWrapper() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;
        return <CmsBlock identifier={ footer_cms } />;
    }

    render() {
        const { isVisibleOnMobile, device } = this.props;
        return (
            <footer block="Footer" aria-label="Footer">
                { this.renderContent() }
            </footer>
        );
    }
}

export default Footer;
