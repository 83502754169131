/* eslint-disable */

import AddSampleToCart from "../component/AddSampleToCart";

export const renderAddToCart = (args, callback, instance) => {
    const {
        product: {
            hoeks_offer_sample: offerSample
        } = {}
    } = instance.props;

    if (offerSample) {
        return (
            <>
                { callback.apply(instance, args) }
                <AddSampleToCart
                    { ...instance.props } 
                    mix={ { block: 'ProductActions', elem: 'AddToCart' } }
                />
            </>
        )
    }

    return callback.apply(instance, args);
}

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderAddToCart
        }
    }
}