/* eslint-disable */
import CmsBlock from 'Component/CmsBlock';

import './AddSampleToCart.style.scss';

export class AddSampleToCart extends PureComponent {
    static defaultProps = {
        product: {},
        mix: {},
        isLoading: false
    };

    renderPlaceholder() {
        const { isLoading, mix } = this.props;

        return (
            <div
              block="AddSampleToCart"
              mods={ { isLoading, isPlaceholder: true } }
              mix={ mix }
            />
        );
    }

    renderSampleDescription() {
        const {
            product: {
                hoeks_sample_description: description
            }
        } = this.props;

        return (
            <div
                block="AddSampleToCart"
                elem="Description"
            >
                <CmsBlock identifier={ description }/>
            </div>
        )
    }

    render() {
        const {
            mix,
            product: {
                type_id,
                hoeks_sample_label: sampleButtonLabel
            },
            isLoading,
            buttonClick
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        const sampleLabel = sampleButtonLabel ?? __('Order sample');

        return (
            <div
                block="AddSampleToCart"
                elem="Wrapper"
            >
                { this.renderSampleDescription() }
                <button
                    onClick={ buttonClick }
                    block="Button AddSampleToCart ProductActions-AddToCart"
                    mods={ { isLoading, type: 'orderSample' } }
                    mix={ mix }
                    disabled={ isLoading }
                >
                    <span>{ sampleLabel }</span>
                    <span>{ __('Adding...') }</span>
                </button>
            </div>
        );
    }
}

export default AddSampleToCart;