/* eslint-disable */
import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';

import AddSampleToCart from './AddSampleToCart.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Component/AddSampleToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({});

/** @namespace Component/AddSampleToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

export class AddSampleToCartContainer extends PureComponent {
    state = {
        isLoading: false
    }

    containerFunctions = {
        buttonClick: this.buttonClick.bind(this)
    }

    addProductToCart() {
        const {
            product: {
                hoeks_sample_product_sku: sampleSku
            },
            addProduct
        } = this.props;

        addProduct({
            product: {
                sku: sampleSku
            },
            quantity: 1
        }).then(
            /** @namespace Component/AddSampleToCart/Container/addSimpleProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddSampleToCart/Container/addSimpleProductToCartAddProductCatch */
            () => this.addToCartError()
        );
    }

    afterAddToCart() {
        const {
            showNotification
        } = this.props;

        showNotification('success', __('Sample added to cart!'));

        this.setState({ isLoading: false });
    }

    addToCartError() {
        const {
            showNotification
        } = this.props;

        showNotification('error', __('Failed to add sample to cart.'));

        this.setState({ isLoading: false });
    }

    buttonClick() {
        this.setState({ isLoading: true }, () => this.addProductToCart());
    }

    render() {
        return (
            <AddSampleToCart
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSampleToCartContainer)