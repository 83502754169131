import { PureComponent } from 'react';
import EanFields from '../component/EanFields';
import { EAN } from '../component/EanFields/EanFields.config';

export class CheckoutPaymentsPlugin extends PureComponent {
    addEanData = (member, context) => {
        return {
            ...member,
            [EAN]: this.getEanData.bind(context)
        }
    }

    getEanData() {
        const { ean_number, company, req_number, reference } = this.props;
        const asyncData = new Promise((resolve, reject) => {
            if (true) {
                resolve({ ean_number: ean_number, company: company, requisition_number: req_number, reference: reference });
            }
            else {
                reject("error");
            }
        });
        return { asyncData: asyncData };
    }

    containerProps = () => {
        return {
            ...this.state
        }
    };

    aroundContainerFunctions = (member, context) => {
        return {
            ...member,
            onEanChange: this.onEanChange.bind(context),
            onCompanyChange: this.onCompanyChange.bind(context),
            onReqChange: this.onReqChange.bind(context),
            onReferenceChange: this.onReferenceChange.bind(context)
        }
    }

    onEanChange(value) {
        this.setState(state => ({
            ...state,
            ean_number: value
        }));
    }

    onCompanyChange(value) {
        this.setState(state => ({
            ...state,
            company: value
        }));
    }

    onReqChange(value) {
        this.setState(state => ({
            ...state,
            req_number: value
        }));
    }

    onReferenceChange(value) {
        this.setState(state => ({
            ...state,
            reference: value
        }));
    }

    addEanRender = (member, context) => {
        return {
            ...member,
            [EAN]: this.renderEanFields.bind(context)
        }
    }

    renderEanFields() {
        const {
            onEanChange,
            onCompanyChange,
            onReqChange,
            onReferenceChange,
            ean_number,
            company,
            req_number,
            reference,
            setOrderButtonEnableStatus
        } = this.props;
        return (
            <EanFields
                onEanChange={ onEanChange }
                onCompanyChange={ onCompanyChange }
                onReqChange={ onReqChange }
                onReferenceChange={ onReferenceChange }
                ean_number={ ean_number }
                company={ company }
                req_number={ req_number }
                reference={ reference }
                setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
            />
        );
    }

    aroundRenderPayments(args, callback, instance) {
        const {
            onEanChange,
            onCompanyChange,
            onReqChange,
            onReferenceChange,
            ean_number,
            company,
            req_number,
            reference
        } = instance.props;

        const result = callback(...args);
        return <result.type
            { ...result.props }
            onEanChange={ onEanChange }
            onCompanyChange={ onCompanyChange }
            onReqChange={ onReqChange }
            onReferenceChange={ onReferenceChange }
            ean_number={ ean_number }
            company={ company }
            req_number={ req_number }
            reference={ reference }
        />;
    }
}

const getEanData = (args, callback, instance) => {
    const { paymentMethod: code } = instance.state;

    if (code === EAN) {
        const [_, asyncData] = args;
        const [{ ean_number, company, requisition_number, reference }] = asyncData;
        return {
            code,
            additional_data: {
                ean_number,
                company,
                requisition_number,
                reference
            }
        };
    }
    return callback(...args);
}

const { addEanRender, addEanData, aroundContainerFunctions, aroundRenderPayments } = new CheckoutPaymentsPlugin();

export default {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderPayments: aroundRenderPayments
        }
    },
    'Component/CheckoutBilling/Container': {
        'member-property': {
            containerFunctions: aroundContainerFunctions
        },
        'member-function': {
            _getPaymentData: getEanData
        }
    },
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap: addEanRender
        }
    },
    'Component/CheckoutPayments/Container': {
        'member-property': {
            dataMap: addEanData
        }
    }
};