/* eslint-disable */
const addConfig = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        'customer_type_general_enabled'
    ];
}

export default {
    'Query/Config': {
        'member-function': {
            '_getStoreConfigFields': addConfig
        }
    }
}