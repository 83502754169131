/* eslint-disable */

const renderConfigurableAttributes = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    if (!result) {
        return result;
    }

    const {
        product
    } = instance.props;

    if (result.props.children && React.Children.count(result.props.children) === 1) {
        const updatedChildren = React.Children.map(result.props.children, (child) => React.cloneElement(child, { product }))

        const updatedResult = React.cloneElement(result, {}, ...updatedChildren);

        return updatedResult;
    }

    return result;
}

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderConfigurableAttributes
        }
    }
}