/* eslint-disable */
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';
import TrustpilotScript from '../component/TrustpilotScript';

const addTrustpilot = (member, instance) => {
    return [
        ...member,
        {
            component: <TrustpilotScript />,
            position: 23
        }
    ]
}

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addTrustpilot
        }
    }
}