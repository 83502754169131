/* eslint-disable */

// imports the original component from vendor folder
import { Overlay as HoeksOverlay } from 'HoeksComponent/Overlay/Overlay.component';

// import new extended styles file
import './Overlay.override.style.scss';

/** @namespace Component/Overlay/Component */
export class Overlay extends HoeksOverlay {
    componentDidMount() {
        const isVisible = this.getIsVisible();
        if (isVisible) {
            this.onVisible();
        }
    }

    componentWillUnmount() {
        this.unfreezeScroll();
    }
}

export default Overlay;
