/* eslint-disable */
export const ADD_PAGE_TYPE = 'ADD_PAGE_TYPE';
export const REMOVE_PAGE_TYPE = 'REMOVE_PAGE_TYPE';

/** @namespace Store/PageTypes/Action/addPageType */
export const addPageType = (pageType) => ({
    type: ADD_PAGE_TYPE,
    pageType
});

/** @namespace Store/PageTypes/Action/removePageType */
export const removePageType = (pageType) => ({
    type: REMOVE_PAGE_TYPE,
    pageType
});