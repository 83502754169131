import Event, { EVENT_GTM_PURCHASE } from '../util/Event';

const loadOrderData = async (args, callback, instance) => {
    const result = await callback(...args);

    if (result === false) {
        return false;
    }

    const {
        totals,
        items,
        orderId
    } = result;

    Event.dispatch(
        EVENT_GTM_PURCHASE,
        { orderID: orderId, totals: { ...totals, items } }
    );

    return result;
};

export default {
    'Route/PayeverGateway/Container': {
        'member-function': {
            loadOrderData
        }
    }
};
