/* eslint-disable */

// imports the original component from vendor folder
import { Header as SourceHeader } from 'HoeksComponent/Header/Header.component';

// import dependencies
import NavigationTabs from 'Component/NavigationTabs';
import Link from 'Component/Link';
import { isSignedIn } from 'Util/Auth';
import Image from 'Component/Image';
import OfflineNotice from 'Component/OfflineNotice';
import CmsBlock from 'Component/CmsBlock';
import {
    CART_OVERLAY,
    CUSTOMER_WISHLIST
} from './Header.config';
import DepartmentMenu from 'Component/DepartmentMenu';
import Menu from 'Component/Menu';
import TextPlaceholder from 'Component/TextPlaceholder';
import ClerkSearchWrapper from 'Component/ClerkSearchWrapper';

// import new extended style file
import './Header.override.style.scss';
import { MENU_STATES } from './Header.config';
import { IS_HOME_PAGE } from '../../plugin/PageTypeClass.plugin';

export class Header extends SourceHeader {
    // Disable topmenu (extends base theme)
    renderTopMenu() {
        const { device } = this.props;
        if (!device.isMobile) {
            return null;
        }

        return (
            <>
                <NavigationTabs />
            </>
        )
    }


    renderTopBar() {
        return (
            <div
                block="TopBar"
            >
                <CmsBlock identifier={ "top-bar" } />
            </div>
        )
    }

    renderSearch() {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <div
                block="Header"
                elem="Search"
            >
                <ClerkSearchWrapper />
            </div>
        )
    }

    renderDepartmentMenu() {
        const { device, isDepartmentStore } = this.props;

        if (!isDepartmentStore) {
            return null;
        }

        if (device.isMobile) {
            return null;
        }

        const whichSite = document.body.classList.contains('picment_dk_store_view') ? 'DK' : 'SE';
        if ( whichSite == 'DK' ) {
            return;
        }

        return (
            <div
                block="Header"
                elem="DepartmentMenu"
            >
                <DepartmentMenu/>
                {this.renderDepartmentTitleWrapper()}
            </div>
        );
    }

    renderWishlistButton(isVisible) {
        const {
            showNotification
        } = this.props;

        if (!isSignedIn()) {
            return (
                <div
                    block="Header"
                    elem="Wishlist"
                >
                    <a onClick={ () => showNotification('info', __('Sign in to see wishlist')) }>
                        <div
                            block="Header"
                            elem="MyWishlistTitle"
                        >
                            { __('Wishlist') }
                        </div>
                        <div
                            block="Header"
                            elem="Button"
                            mods={ { isVisible, type: 'wishlist' } }
                        />
                    </a>
                </div>
            )
        }

        return (
            <div
                block="Header"
                elem="Wishlist"
            >
                <Link
                    block="Header"
                    elem="MyWishlist"
                    tabIndex="0"
                    aria-label="Wishlist"
                    id="wishlist"
                    to="my-account/my-wishlist"
                >
                    <div
                        block="Header"
                        elem="MyWishlistTitle"
                    >
                        { __('Wishlist') }
                    </div>
                    <div
                        block="Header"
                        elem="Button"
                        mods={ { isVisible, type: 'wishlist' } }
                    />
                </Link>
            </div>
        );
    }

    // Disable search field in header
    renderSearchField() {
        return null;
    }

    renderBackButton() {
        return null;
    }

    renderLogo(isVisible = false) {
        const { isLoading } = this.props;

        const {
            mix
        } = this.props;

        if (isLoading) {
            return null;
        }

        return (
            <Link
              to="/"
              aria-label="Go to homepage by clicking on the logo"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
              block="Header"
              elem="LogoWrapper"
              mods={ { isVisible } }
              key="logo"
            >
                <Image
                    imageRef={this.imageRef}
                    // src="/media/logo/svg/picment-logo-sort-220.svg"
                    src="/media/logo/svg/picment-logo-sort-tagline.svg"
                    // srcSet={sources}
                    // sizes={sizes}
                    alt="Picment logo"
                    ratio="custom"
                    mix={{ block: 'Header', elem: 'SvgLogo', mix }}
                    width="215"
                    height="55"
                    loading="eager"
                />
            </Link>
        );
    }

    // Added Clerk search render
    renderMap = {
        departmentMenu: this.renderDepartmentMenu.bind(this),
        clerkSearch: this.renderSearch.bind(this),
        // departmentTitle: this.renderDepartmentTitleWrapper.bind(this),
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        share: this.renderShareWishListButton.bind(this),
        title: this.renderTitle.bind(this),
        logo: this.renderLogo.bind(this),
        account: this.renderAccount.bind(this),
        wishlist: this.renderWishlistButton.bind(this),
        minicart: this.renderMinicart.bind(this),
        compare: this.renderComparePageButton.bind(this),
        search: this.renderSearchField.bind(this),
        clear: this.renderClearButton.bind(this),
        edit: this.renderEditButton.bind(this),
        ok: this.renderOkButton.bind(this),
    };

    renderDepartmentTitleWrapper() {
        const { pageTypes } = this.props;
        const shouldRender = pageTypes.some(pt => MENU_STATES.some(pageType => pt === pageType));

        if (shouldRender) {
            return (
                <div
                    block="Header"
                    elem="DepartmentTitle"
                >
                    { this.renderDepartmentTitle() }
                </div>
            );
        }

        return null;
    }

    renderDepartmentTitle() {
        const { currentDepartment } = this.props;

        if (currentDepartment) {
            const { name, url } = currentDepartment;
            return (
                <Link
                    block="Header"
                    elem="DepartmentTitleLink"
                    to={ url }
                >
                    { name }
                </Link>
            );
        }

        return (
            <TextPlaceholder/>
        );
    }

    renderMenu() {
        const { isCheckout, device, menuId, pageTypes, departmentMenu, isDepartmentStore } = this.props;

        if (!isDepartmentStore) {
            return super.renderMenu();
        }

        if (device.isMobile || isCheckout) {
            return null;
        }

        if (pageTypes.some(pt => pt === IS_HOME_PAGE)) {
            return <Menu overrideMenu={ departmentMenu } overrideOnly={ true } />;
        }

        // if ( ! pageTypes.some( pt => MENU_STATES.some( pageType => pt === pageType ) ) ) {
        //     return null;
        // }

        return <Menu overrideMenu={ menuId } overrideOnly={ true } />;
    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device
        } = this.props;

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        // Check for body class defining site/language
        const whichSite = document.body.classList.contains('picment_dk_store_view') ? 'DK' : 'SE';

        // DESKTOP
        if ( ! device.isMobile ) {

            if ( whichSite == 'DK' ) {
                return (
                    <section block="Header" elem="Wrapper">
                        { this.renderTopBar() }
                        <header
                          block="Header"
                          mods={ { name, isHiddenOnMobile, isCheckout } }
                          mix={ { block: 'FixedElement', elem: 'Top' } }
                          ref={ this.logoRef }
                        >
                            { this.renderTopMenu() }
                            <nav block="Header" elem="Nav">
                                <NavigationTabs />
                                {/* This is the desktop menu */}
                                {/* VIC: New menu: menu is moved beside logo */}
                                { this.renderMenu() }

                                { this.renderNavigationState() }
                            </nav>
                            {/*  */}
                        </header>
                        <OfflineNotice />
                    </section>
                );
            } else {
                return (
                    <section block="Header" elem="Wrapper">
                        { this.renderTopBar() }
                        <header
                          block="Header"
                          mods={ { name, isHiddenOnMobile, isCheckout } }
                          mix={ { block: 'FixedElement', elem: 'Top' } }
                          ref={ this.logoRef }
                        >
                            { this.renderTopMenu() }
                            <nav block="Header" elem="Nav">
                                <NavigationTabs />

                                { this.renderNavigationState() }
                            </nav>
                            { this.renderMenu() }
                        </header>
                        <OfflineNotice />
                    </section>
                );
            }

        }

        // MOBILE

        if ( whichSite == 'DK' ) {
            return (
                <section block="Header" elem="Wrapper">
                    {/* { this.renderTopBar() } */}
                    <header
                    block="Header"
                    mods={ { name, isHiddenOnMobile, isCheckout } }
                    mix={ { block: 'FixedElement', elem: 'Top' } }
                    ref={ this.logoRef }
                    >
                        {/* This is the desktop menu */}
                        { this.renderMenu() }
                        {/* This is the mobile menu */}
                        { this.renderTopMenu() }
                        <nav block="Header" elem="Nav">
                            { this.renderNavigationState() }
                        </nav>

                    </header>
                    <OfflineNotice />
                </section>
            );
        } else {
            return (
                <section block="Header" elem="Wrapper">
                    { this.renderTopBar() }
                    <header
                    block="Header"
                    mods={ { name, isHiddenOnMobile, isCheckout } }
                    mix={ { block: 'FixedElement', elem: 'Top' } }
                    ref={ this.logoRef }
                    >
                        {/* This is the desktop menu */}
                        { this.renderMenu() }
                        {/* This is the mobile menu */}
                        { this.renderTopMenu() }
                        <nav block="Header" elem="Nav">
                            { this.renderNavigationState() }
                        </nav>

                    </header>
                    <OfflineNotice />
                </section>
            );
        }
    }


}

export default Header;
