/* eslint-disable */

// imports the original component from vendor folder
import SourceProductList from 'SourceComponent/ProductList/ProductList.component';

// import new extended styles file
import './ProductList.override.style.scss';

/** @namespace Component/ProductList/Component */
export class ProductList extends SourceProductList { 

    renderLoadButton() {
        const {
            isShowLoading,
            isInfiniteLoaderEnabled,
            loadPrevPage
        } = this.props;

        if (!isShowLoading || !isInfiniteLoaderEnabled) {
            return null;
        }

        return (
            <div
            block="ProductList"
            elem="LoadButtonContainer"
            >
                <div
                block="ProductList"
                elem="LoadButton Button"
                role="button"
                tabIndex="0"
                onKeyUp={ loadPrevPage }
                onClick={ loadPrevPage }
                >
                    { __('Load previous') }
                </div>
            </div>
        );
    }
    
    render() {
        const {
            totalPages,
            isLoading,
            mix
        } = this.props;

        if (!isLoading && totalPages === 0) {
            return this.renderNoProducts();
        }

        return (
            <div
              block="ProductList"
              mods={ { isLoading } }
              mix={ mix }
            >
                { this.renderTitle() }
                { this.renderLoadButton() }
                { this.renderPages() }
                { this.renderPagination() }
            </div>
        );
    }
    

}

export default ProductList;
