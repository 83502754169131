/* eslint-disable */
import { MenuContainer as HoeksMenuContainer, mapStateToProps, mapDispatchToProps } from 'HoeksComponent/Menu/Menu.container';
import { connect } from 'react-redux';
import MenuHelper from 'Util/Menu';
import MenuQuery from 'Query/Menu.query';

/** @namespace Component/Menu/Container */
export class MenuContainer extends HoeksMenuContainer {
    
    /*
    componentDidUpdate(prevProps) {

        const {
            overrideMenu
        } = this.props;

        const {
            overrideMenu: prevOverrideMenu
        } = prevProps;

        if (overrideMenu !== prevOverrideMenu) {
            this._getMenu();
        }
    }

    populateHeaderStateFromStack = () => {
        const { changeHeaderState } = this.props;
        const { activeMenuItemsStack, menu } = this.state;
        const [mainMenu] = Object.values(menu);

        activeMenuItemsStack.slice().reduceRight((acc, itemId) => {
            const {
                children: {
                    [itemId]: currentItem
                }
            } = acc;

            const { title } = currentItem;

            changeHeaderState({
                name: MENU_SUBCATEGORY,
                force: true,
                title,
                onBackClick: this.handleHeaderBackClick
            });

            return currentItem;
        }, mainMenu);
    };

    _getMenuOptions() {
        const { header_content: { header_menu } = {} } = window.contentConfiguration;
        const { overrideMenu } = this.props;

        return {
            identifier: overrideMenu || header_menu || 'new-main-menu'
        };
    }

    _getMenu() {
        const { overrideOnly, overrideMenu } = this.props;
        if (overrideOnly && !overrideMenu) {
            return;
        }

        this.fetchData(
            [MenuQuery.getQuery(this._getMenuOptions())],
            ({ menu }) => this.setState({
                menu: MenuHelper.reduce(menu),
                activeMenuItemsStack: []
            }, this.populateHeaderStateFromStack)
        );
    }
    */
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);