/* eslint-disable */
// import { MenuOverlayContainer as HoeksMenuOverlayContainer, mapStateToProps, mapDispatchToProps } from 'HoeksComponent/MenuOverlay/MenuOverlay.container';
import { MenuOverlayContainer as HoeksMenuOverlayContainer } from 'HoeksComponent/MenuOverlay/MenuOverlay.container';
import { mapStateToProps, mapDispatchToProps } from 'SourceComponent/Menu/Menu.container';
import { connect } from 'react-redux';

export * from 'HoeksComponent/MenuOverlay/MenuOverlay.container';

/** @namespace Component/MenuOverlay/Container */
export class MenuOverlayContainer extends HoeksMenuOverlayContainer {
    shouldComponentUpdate(nextProps, nextState) {
        const { activeMenuItemsStack } = this.state;
        const { activeMenuItemsStack: nextActiveMenuItemsStack } = nextState;
        activeMenuItemsStack.forEach((menuItem, index) => {
            if (nextActiveMenuItemsStack[index] != menuItem) {
                return true;
            }
        });
        const stateChanged = this.shallowDiffers(this.state, nextState);
        const propsChanged = this.shallowDiffers(this.props, nextProps);
        return stateChanged || propsChanged ? true : false;
    }

    shallowDiffers (a, b) {
        for (let i in a) if (!(i in b)) return true
        for (let i in b) if (a[i] !== b[i]) return true
        return false
      }

    handleSubcategoryClick(e, activeSubcategory, isFirstLevelItem = false) {
        const { changeHeaderState, closeOverlayMenu } = this.props;
        const { activeMenuItemsStack, menu } = this.state;
        const { item_id, title } = activeSubcategory;

        
        e.stopPropagation();
        document.d
        if (activeMenuItemsStack.includes(item_id)) {
            const newActiveMenuItemsStack = activeMenuItemsStack.filter(id => item_id !== id);
            this.setState({ activeMenuItemsStack: newActiveMenuItemsStack });            
            
            // Closing sublevel
            e.target.closest('.Menu-ItemFigure').classList.toggle('Menu-Item__open');

            return;
        }

        if (isFirstLevelItem) {
            const newActiveMenuItemsStack = [item_id];
            this.setState({ activeMenuItemsStack: newActiveMenuItemsStack });

            // Click on first level item
            e.target.closest('.Menu-ItemFigure').classList.toggle('Menu-Item__open');
            return;
        } else {
            if (activeMenuItemsStack.length >= 2) {
                const newActiveMenuItemsStack = this.checkActiveMenuParentId(item_id, menu['3000'], activeMenuItemsStack.length - 1);
                this.setState({ activeMenuItemsStack: [...newActiveMenuItemsStack] });

                e.target.closest('.Menu-ItemFigure').classList.toggle('Menu-Item__open');
                return;
            }
        }
        
        // Opening a level
        e.target.closest('.Menu-ItemFigure').classList.toggle('Menu-Item__open');

        const newActiveMenuItemsStack = [item_id, ...activeMenuItemsStack];
        this.setState({ activeMenuItemsStack: newActiveMenuItemsStack });

    }

    checkActiveMenuParentId(menu_id, menu, index, parent_id = 0) {
        const { activeMenuItemsStack } = this.state
        if (Object.keys(menu.children).length > 0 && menu.children[menu_id] == undefined) {
            const newIndex = index != 0 ? index - 1 : index;
            if (index == 0) return [menu_id, ...activeMenuItemsStack];
            return this.checkActiveMenuParentId(menu_id, menu.children[activeMenuItemsStack[index]], newIndex, menu.parent_id);
        } else {
            var newActiveMenuItemsStack = activeMenuItemsStack;
            newActiveMenuItemsStack[0] = menu_id;
            return newActiveMenuItemsStack;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuOverlayContainer);