/* eslint-disable */
import SourceUrlRewrites from 'HoeksRoute/UrlRewrites/UrlRewrites.component';

export class UrlRewrites extends SourceUrlRewrites {
    renderDefaultPage() {
        return (
            <main style={{height: '100vh'}}/>
        );
    }
}

export default UrlRewrites;
