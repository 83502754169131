/* eslint-disable */
import ColorSwatchPicker from './ColorSwatchPicker.component';
import { connect } from 'react-redux';
import { showPopup } from 'Store/Popup/Popup.action';
import { hideActivePopup } from 'Store/Overlay/Overlay.action';
import history from 'Util/History';

export const ColorSwatchDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/ColorSwatch/ColorSwatch.dispatcher'
);

/** @namespace Component/ColorSwatchPicker/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    colorCollections: state.ColorSwatchReducer.colorCollections,
    product: state.ProductReducer.product,
    activeOverlay: state.OverlayReducer.activeOverlay
});

/** @namespace Component/ColorSwatchPicker/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload, id) => dispatch(showPopup(id, payload)),
    getColorCollections: (collections) => ColorSwatchDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, { collections })
    ),
    hideActivePopup: () => dispatch(hideActivePopup())
});

/** @namespace Component/ColorSwatchPicker/Container */
export class ColorSwatchPickerContainer extends PureComponent {
    state = {
        selectedCollection: null,
        didFetchColors: false
    }

    onPopupOpenClick() {
        const {
            showPopup,
            option: {
                title = ''
            }
        } = this.props;

        this.fetchColors();
        showPopup({ title: __('Choose %s', title.toLowerCase()) }, this.getPopupId());
    }

    fetchColors() {
        const {
            getColorCollections,
            product: {
                attributes: {
                    color_collections: {
                        attribute_options = {}
                    } = {}
                } = {}
            } = {}
        } = this.props;

        const {
            didFetchColors
        } = this.state;

        if (!didFetchColors) {
            const collections = Object.entries(attribute_options).map(([, { label } = {}] = []) => label);
            this.setState(state => ({
                ...state,
                collections,
                didFetchColors: true
            }))
            getColorCollections(collections);
        }
    }

    onColorSelect(color) {
        const {
            updateTextFieldValue,
            hideActivePopup,
            activeOverlay
        } = this.props;

        if (activeOverlay == this.getPopupId()) {
            history.goBack();
            hideActivePopup();
        }
        updateTextFieldValue(color);
    }

    onCollectionSelect(name) {
        this.setState(state => ({
            ...state,
            selectedCollection: name
        }))
    }

    getPopupId() {
        const {
            option: {
                option_id
            }
        } = this.props;

        return option_id;
    }

    containerFunctions() {
        return {
            onColorSelect: this.onColorSelect.bind(this),
            onPopupOpenClick: this.onPopupOpenClick.bind(this),
            onCollectionSelect: this.onCollectionSelect.bind(this)
        }
    }

    getColorCollections() {
        const {
            collections = []
        } = this.state;

        const {
            colorCollections = []
        } = this.props;

        return colorCollections.filter(({ code }) => collections.some(collection => collection === code));
    }

    containerProps = () => {
        return {
            popupId: this.getPopupId(),
            colorCollections: this.getColorCollections()
        }
    }

    render() {
        return (
            <ColorSwatchPicker
                { ...this.props }
                { ...this.containerFunctions() }
                { ...this.containerProps() }
                { ...this.state }
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ColorSwatchPickerContainer);