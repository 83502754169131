/* eslint-disable */
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';
import ClerkScript from '../component/ClerkScript';

const addClerk = (member, instance) => {
    return [
        ...member,
        {
            component: <ClerkScript />,
            position: 27
        }
    ]
}

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addClerk
        }
    }
}