/* eslint-disable */
import { CategoryQuery as SourceCategoryQuery } from 'SourceQuery/Category.query';

/** @namespace Query/Category */
export class CategoryQuery extends SourceCategoryQuery {
    _getDefaultFields() {
        return [
            ...super._getDefaultFields(),
            'category_seo_text'
        ]
    }
}

export default new CategoryQuery()