/* eslint-disable */
import { getIndexedVariants } from 'Util/Product';
import { UPDATE_PRODUCT_VARIANTS, updateProductVariants } from '../store/Product/Product.action';
import ProductListQuery from 'Query/ProductList.query';
import { UPDATE_PRODUCT_DETAILS } from 'Store/Product/Product.action';
import { showNotification } from 'Store/Notification/Notification.action';

const onSuccess = (args, callback, instance) => {
    const [ data, dispatch, options ] = args;
    const { isVariant, isAllVariants, variantOptions: { isVariant: extraOptionsIsVariant } = {} } = options;

    if (extraOptionsIsVariant) {
        const { variant: { items = [] } = {} } = data;
        callback.apply(instance, args);
        dispatch(updateProductVariants(items));
        return;
    }

    if (isVariant) {
        const { products: { items = [] } = {} } = data;
        dispatch(updateProductVariants(items));
        return;
    }

    if (isAllVariants) {
        const { products: { items: [ { variants = [] } = {} ] = [] } = {} } = data;
        dispatch(updateProductVariants(variants.map(({ product }) => ({ ...product })), true));
        return;
    }

    return callback.apply(instance, args);
}

const onError = (args, callback, instance) => {
    const [ , dispatch, options ] = args;
    const { isVariant, isAllVariants } = options;

    if (isVariant) {
        return null;
    }

    if (isAllVariants) {
        return dispatch(showNotification('error', __('Something went wrong, please refresh page.')));
    }

    return callback.apply(instance, args);
}

const prepareRequest = (args, callback, instance) => {
    const [ options ] = args;
    const {
        variantOptions,
        ...otherOptions
    } = options;

    if (variantOptions) {
        return [ ProductListQuery.getQuery(otherOptions), ProductListQuery.getQuery(variantOptions).setAlias('variant') ];
    }

    return callback.apply(instance, args);
}

const formatVariants = (variants, newVariants) => {
    const indexedNewVariants = getIndexedVariants(newVariants.map(item => ({ product: item })));

    if (variants.length && newVariants.length > variants.length) {
        const result = [ ...variants, ...indexedNewVariants.filter(({ sku: newSku }) => !variants.some(({ sku }) => sku === newSku)) ];
        return result;
    }

    if (variants.length) {
        return variants.map(({ sku, ...rest }) => {
            const newVariant = indexedNewVariants.find(({ sku: newSku }) => sku === newSku);

            if (newVariant) {
                return newVariant;
            }

            return { ...rest, sku };
        });
    }

    return indexedNewVariants;
}

const reducer = (args, callback, instance) => {
    const [ state, action ] = args;

    switch (action.type) {
        case UPDATE_PRODUCT_VARIANTS:
            const { variants: actionVariants, areVariantsLoaded = false } = action;
            const {
                product: {
                    variants = []
                } = {},
                product = {},
                areVariantsLoaded: stateAreVariantsLoaded
            } = state;

            return {
                ...state,
                product: {
                    ...product,
                    variants: formatVariants(variants, actionVariants)
                },
                areVariantsLoaded: areVariantsLoaded || stateAreVariantsLoaded
            };
        case UPDATE_PRODUCT_DETAILS:
            return {
                ...callback.apply(instance, args),
                areVariantsLoaded: false
            };
        default:
            return callback.apply(instance, args);
    }
}

const getInitialState = (args, callback, instance) => {
    const result = callback.apply(instance, args);
    return {
        ...result,
        areVariantsLoaded: false
    };
}

export default {
    'Store/Product/Dispatcher': {
        'member-function': {
            onSuccess,
            onError,
            prepareRequest
        }
    },
    'Store/Product/Reducer': {
        'function': reducer
    },
    'Store/Product/Reducer/getInitialState': {
        'function': getInitialState
    }
}