import { PureComponent } from 'react';
import Clerk from '../Clerk';
import Event from '@scandipwa/gtm/src/util/Event';
import { isEventEnabled } from '@scandipwa/gtm/src/util/EventConfig';

/** @namespace Hoeks/Clerk/Component/ClerkEvent/Component/ClerkEventComponent */
export class ClerkEventComponent extends PureComponent {
    state = {
        showClerk: false
    }

    componentDidMount(){
        this.bindEvent();
    }

    componentWillUnmount(){
        this.unbind();
    }
    
    unbind(){
        const { event } = this.props;
        if (this.listener){
            Event.removeObserver(event, this.listener);
        }
    }
    
    bindEvent() {
        const { event } = this.props;
        this.listener = Event.observer(event, this.show);
    }


    show = () => {
        this.setState({showClerk: true});
    }  

    render() {
        const {showClerk} = this.state;
        if (showClerk){
            return <Clerk {...this.props} />;
        }
        return null;
    }
}

export default ClerkEventComponent;
