/* eslint-disable */

import {MIN_PASSWORD_LENGTH, validateEmail, validateEmails, validateTelephone, isNotEmpty, validatePasswordMatch} from 'HoeksComponent/Form/Form.config';

export const validatePassword = ({ value }) => {
    const isMatchingCharactersLimit = value.length >= MIN_PASSWORD_LENGTH;
    const containsLowerCase = /[a-z]/;
    const containsUpperCase = /[A-Z]/;
    const containsDigit = /\d/;
    const containsSpecial = /[^a-zA-Z\d]/;
    var charClassAmount = 0;

    if (containsLowerCase.test(value)) {
        charClassAmount++
    }
    if (containsUpperCase.test(value)) {
        charClassAmount++
    }
    if (containsDigit.test(value)) {
        charClassAmount++
    }
    if (containsSpecial.test(value)) {
        charClassAmount++
    }

    if (isMatchingCharactersLimit && charClassAmount >= 3) {
        return true;
    } else {
        return false;
    }
};

/** @namespace Component/Form/Config */
export const formConfig = () => ({
    email: {
        validate: validateEmail,
        message: __('Email is invalid.')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    password: {
        validate: validatePassword,
        message: __('Password should be at least 8 characters long, and should contain letters, numbers and special characters')
    },
    telephone: {
        validate: validateTelephone,
        message: __('Phone number is invalid!')
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!')
    },
    password_match: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    }
});

export default formConfig();