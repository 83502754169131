import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ClerkScript from './ClerkScript.component';
import { CLERK_ENABLED, CLERK_KEY } from '../Clerk/Clerk.config';

/** @namespace Hoeks/Clerk/Component/ClerkScript/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    enabled: state.ConfigReducer[CLERK_ENABLED],
    apikey: state.ConfigReducer[CLERK_KEY],
    customer: state.MyAccountReducer.customer
});

/** @namespace Hoeks/Clerk/Component/ClerkScript/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
});

/** @namespace Hoeks/Clerk/Component/ClerkScript/Container/ClerkScriptContainer */
export class ClerkScriptContainer extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool,
        apikey: PropTypes.string
    };

    containerFunctions = {
        setScriptState: this.setScriptState.bind(this)
    };

    setScriptState(ready){
        const { updateScriptState } = this.props;
    }

    containerProps = () => {
        return this.props
    };

    render() {
        return (
            <ClerkScript
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClerkScriptContainer);
