/* eslint-disable */
import { Field } from 'Util/Query';

const _getProductInterfaceFields = (args, callback, instance) => {
    const result = callback.apply(instance, args);
    const [ isVariant ] = args;
    const { isSingleProduct, isVariant: optionIsVariant } = instance.options;

    if (isSingleProduct) {
        if (isVariant && !optionIsVariant) {
            const filteredResult = result.filter(field => field.name !== 'media_gallery_entries');
            return filteredResult;
        }
    }

    return result;
}

const _getItemsField = (args, callback, instance) => {
    const { isVariant } = instance.options;

    if (isVariant) {
        const items = new Field('items')
            .addFieldList(instance._getProductInterfaceFields(true, false));

        return items;
    }

    return callback.apply(instance, args);
}

const _getVariantsField = (args, callback, instance) => {
    const { isVariant: optionIsVariant, isAllVariants, isForLinkedProducts } = instance.options;

    if (optionIsVariant || isAllVariants || isForLinkedProducts) {
        return callback.apply(instance, args);
    }

    return;
}

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields,
            _getItemsField,
            _getVariantsField
        }
    }
}