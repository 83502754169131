/* eslint-disable */

// imports the original component from vendor folder
import HeoksWidgetFactory from 'HoeksComponent/WidgetFactory/WidgetFactory.component';
import { lazy } from 'react';

export const VideoWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "video-widget" */ '../Video'));

const NEWSLETTER = 'Newsletter';
const HOEKS_VIDEO_WIDGET = 'HoeksVideoWidget';

export class WidgetFactory extends HeoksWidgetFactory {
    renderContent() {
        const { type } = this.props;
        const {
            component: Widget,
            fallback
        } = this.renderMap[type] || {};

        const wrappedFallback = typeof fallback === 'function' ? () => fallback(this.props) : fallback;

        if (Widget !== undefined) {
            return (
                // Disabled RenderWhenVisible here, as it causes all product sliders to jump content bellow it (content repaint)
                // <RenderWhenVisible fallback={ wrappedFallback }>
                    <Widget { ...this.props } />
                // </RenderWhenVisible>
            );
        }

        return null;
    }
}

export default WidgetFactory;
