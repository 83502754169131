/* eslint-disable */

// imports the original component from vendor folder
import SourceStoreSwitcher from 'SourceComponent/StoreSwitcher/StoreSwitcher.component';

// import new extended styles file
import './StoreSwitcher.override.style.scss';

export class StoreSwitcher extends SourceStoreSwitcher { 

}

export default StoreSwitcher;
