/* eslint-disable */
import { getStaticReducers as hoeksGetStaticReducers } from 'HoeksStore/index';
import { PageTypesReducer } from 'Store/PageTypes/PageTypes.reducer';

/** @namespace Store/Index/getReducers */
export const getStaticReducers = () => ({
    ...hoeksGetStaticReducers(),
    PageTypesReducer
})

export default function injectStaticReducers(store) {
    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}