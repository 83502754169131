/* eslint-disable */

// imports the original component from vendor folder
import HoeksContentWrapper from 'HoeksComponent/ContentWrapper/ContentWrapper.component';

// import new extended styles file
import './ContentWrapper.override.style.scss';

export class ContentWrapper extends HoeksContentWrapper { 

}

export default ContentWrapper;
