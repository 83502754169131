/* eslint-disable */
import HoeksCmsBlock from 'HoeksComponent/CmsBlock/CmsBlock.component';

/** @namespace Component/CmsBlock/Component */
export class CmsBlock extends HoeksCmsBlock {
    renderPlaceholder() {
        const {
            children
        } = this.props;

        if (children) {
            return children;
        }

        return null;
    }
}

export default CmsBlock;