/* eslint-disable */
import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';

export class ProductPrice extends SourceProductPrice {
    renderOldPrice() {
        const {
            roundedRegularPrice,
            discountPercentage,
            isSchemaRequired,
            variantsCount,
            price,
            roundedOldPrice
        } = this.props;

        const schema = isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : {};

        if (discountPercentage === 0) {
            return null;
        }

        if (roundedOldPrice != "0.00" && roundedOldPrice !== null) {
            return (
                <del
                    block="ProductPrice"
                    elem="HighPrice"
                    aria-label={ __('Old product price') }
                    { ...schema }
                >
                    { roundedOldPrice }
                </del>
            );
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              { ...schema }
            >
                { roundedRegularPrice }
            </del>
        );
    }
}

export default ProductPrice;