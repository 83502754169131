/* eslint-disable */
import { NavigationTabs as SourceNavigationTabs } from 'SourceComponent/NavigationTabs/NavigationTabs.component';
import MenuOverlay from 'Component/MenuOverlay';
import './NavigationTabs.base.override.style.scss';
export * from 'SourceComponent/NavigationTabs/NavigationTabs.component';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

export class NavigationTabs extends SourceNavigationTabs {
    renderMap = {
        menu: this.renderMenuButton.bind(this),
        home: this.renderHomeButton.bind(this),
        account: this.renderAccountButton.bind(this),
        minicart: this.renderMinicartButton.bind(this),
    }

    renderHomeButton(isActive = false) {
        const { onHomeButtonClick, header_logo_src } = this.props;
        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        return (
            <button
                key="home"
                block="NavigationTabs"
                elem="Button"
                aria-label="Home"
                onClick={ onHomeButtonClick }
                className="NavigationTabs-LogoBtn"
            >
                <div
                    block="Header"
                    elem="Button"
                    mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                    mods={ { type: 'home', isVisible: true } }
                    className="NavigationTabs-LogoWrapper"
                >
                    <img
                        style={{width: 120, height: 26}}
                        src={ logoSrc }
                        className="NavigationTabs-LogoImg"
                    />
                </div>
            </button>
        );
    }

    renderMenuOverlay(){
        const { closeOverlayMenu, showMenu = false } = this.props;
        const isActive = showMenu === true;
        return <MenuOverlay isActive={ isActive } key="overlay" closeOverlayMenu={closeOverlayMenu}/>
    }

    renderMenuButton(_) {
        const { onMenuButtonClick, closeOverlayMenu, showMenu = false } = this.props;
        const isActive = showMenu === true;

        return (
            <React.Fragment key="menu">
                <button
                    key="menu"
                    block="NavigationTabs"
                    elem="Button"
                    aria-label="Go to menu and search"
                    onClick={ onMenuButtonClick }
                >
                    <div
                        block="Header"
                        elem="Button"
                        mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                        mods={ { isVisible: true, type: 'menu' } }
                    />
                </button>
            </React.Fragment>

        );
    }

    render() {
        const { device } = this.props;
        if (!device.isMobile) {
            return this.renderMenuOverlay();
        }

        return (
            <nav className="NavigationTabs-Nav">
                { this.renderNavigationState() }
                { this.renderMenuOverlay() }
            </nav>
        )
    }
}

export default NavigationTabs;