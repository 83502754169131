/* eslint-disable */
import { Suspense, lazy } from 'react';
import Loader from 'Component/Loader';

export const TYPE_CUSTOM_IMAGE_PRODUCT = 'CUSTOM_IMAGE_PRODUCT';

export const CustomImageProductPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "customImageProduct" */  '../route/CustomImageProductPage'));

const renderContent = (args, callback, instance) => {
    const { props, type } = instance.props;

    switch (type) {
        case TYPE_CUSTOM_IMAGE_PRODUCT:
            return <Suspense fallback={ <Loader /> }><CustomImageProductPage { ...props } /></Suspense>
        default:
            return callback.apply(instance, args);
    }
}

const getTypeSpecificProps = (args, callback, instance) => {
    const {
        urlRewrite: {
            id,
            sku
        }
    } = instance.props;

    const isLoading = instance.getIsLoading();

    switch(instance.getType()) {
        case TYPE_CUSTOM_IMAGE_PRODUCT:
            /**
             * In case we are not yet sure what product ID it is:
             * - check if there is a hint in browser history
             * - fallback to none
             */
            if (isLoading) {
                const product = history?.state?.state?.product;

                if (product) {
                    const { sku: historySKU, id } = product;
                    return { productSKU: historySKU, id };
                }

                return {};
            }

            return { productSKU: sku, id };
        default:
            return callback.apply(instance, args);
    }
}

export default {
    'Route/UrlRewrites/Component': {
        'member-function': {
            renderContent
        }
    },
    'Route/UrlRewrites/Container': {
        'member-function': {
            getTypeSpecificProps
        }
    }
}