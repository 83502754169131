/* eslint-disable */
import { Menu as SourceMenu } from 'SourceComponent/Menu/Menu.component';
export * from 'SourceComponent/Menu/Menu.component'

import './Menu.base.override.style.scss';

export class Menu extends SourceMenu {
    componentDidMount() {
        super.componentDidMount();

        window.removeEventListener('scroll', this.debouncedCloseMenu);
    }
}

export default Menu;