/* eslint-disable */
import { ConfigQuery as HoeksConfigQuery } from 'HoeksQuery/Config.query';

/** @namespace Query/Config */
export class ConfigQuery extends HoeksConfigQuery {
    _getStoreConfigFields() {
        return [
            ...super._getStoreConfigFields(),
            'department_menu',
            'is_department_store',
            'customer_show_company',
            'locale'
        ];
    }
}

export default new ConfigQuery();