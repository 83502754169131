/* eslint-disable */
import CategoryNavigator from './CategoryNavigator.component';
import DataContainer from 'Util/Request/DataContainer';
import CategoryQuery from 'Query/Category.query';

/** @namespace Component/CategoryNavigator/Container */
export class CategoryNavigatorContainer extends DataContainer {
    state = {
        isLoaded: false,
        siblings: []
    }

    componentDidMount() {
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);

        const {
            category: {
                id
            } = {},
            categoryIds
        } = this.props;

        if (!this.isLeaf()) {
            this.setState(state => ({
                ...state,
                isLoaded: true
            }));
        } else if (categoryIds && id) {
            this.fetchParentCategory();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            category: {
                id
            } = {},
            categoryIds
        } = this.props;

        const {
            category: {
                id: prevId
            } = {},
            categoryIds: prevCategoryIds
        } = prevProps;

        if (categoryIds !== prevCategoryIds) {
            if (this.promise) {
                this.promise.cancel();
            }

            this.setState(state => ({
                ...state,
                isLoaded: false
            }))
        }

        if (id !== prevId) {
            if (this.isLeaf()) {
                this.setState(state => ({
                    ...state,
                    isLoaded: false,
                    siblings: []
                }));
                this.fetchParentCategory();
            } else {
                this.setState(state => ({
                    ...state,
                    isLoaded: true,
                    siblings: []
                }));
            }
        }
    }

    fetchParentCategory() {
        const {
            category: {
                parent_id
            } = {}
        } = this.props;

        if (!parent_id) {
            this.setState(state => ({
                ...state,
                isLoaded: true,
                siblings: []
            }));
            return;
        }

        this.fetchData(
            [CategoryQuery.getQuery({ categoryIds: parent_id })],
            this.onSuccess,
            this.onError
        );
    }

    onSuccess(data) {
        const {
            category: {
                children = []
            } = {}
        } = data;

        this.setState(state => ({
            ...state,
            isLoaded: true,
            siblings: children
        }))
    }

    onError(err) {
        this.setState(state => ({
            ...state,
            isLoaded: true,
            siblings: []
        }))
    }

    getCategories() {
        const {
            category: {
                children = []
            } = {}
        } = this.props;

        const {
            siblings
        } = this.state;

        if (this.isLeaf()) {
            return siblings;
        }

        return children;
    }

    getTitle() {
        const {
            category: {
                name = ''
            } = {}
        } = this.props;

        if (this.isLeaf()) {
            return __('Other');
        }

        return name;
    }

    isLeaf() {
        const {
            category: {
                children = []
            } = {}
        } = this.props;

        if (!Array.isArray(children) || !children.length) {
            return true;
        }

        return false;
    }

    containerProps = () => {
        const {
            isLoaded
        } = this.state;

        const {
            category: {
                id
            } = {},
            categoryIds
        } = this.props;

        return {
            categories: this.getCategories(),
            title: this.getTitle(),
            isLoaded,
            categoryId: id,
            categoryIds
        };
    }

    render() {
        return (
            <CategoryNavigator
                { ...this.containerProps() }
            />
        )
    }
}

export default CategoryNavigatorContainer;