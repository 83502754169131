/* eslint-disable */
import { Router as SourceRouter } from 'HoeksComponent/Router/Router.component';
import {
    BEFORE_ITEMS_TYPE
} from 'Component/Router/Router.config';
import NavigationTabs from 'Component/NavigationTabs';
import history from 'Util/History';

export * from 'HoeksComponent/Router/Router.component';

export class Router extends SourceRouter {
    [BEFORE_ITEMS_TYPE] = [
        ...this.BEFORE_ITEMS_TYPE.filter(({ component: { type } }) => type !== NavigationTabs)
    ]

    componentDidMount(){
        super.componentDidMount();

        this.restoreScroll = this.restoreScroll.bind(this);

        history.listen((_, type) => {
            this.triggerSleeknote();
            if (type === "POP") {
                this.restoreScroll(Date.now(), 5000, 30);
            }
        });
    }

    triggerSleeknote() {
        const emptySleeknotes = document.querySelectorAll('div:empty[id*=sn-]');

        emptySleeknotes.forEach(emptySleeknote => {
            const snId = emptySleeknote.id.substring(3);
            const {
                SleekNote: {
                    triggerOnClick = () => {}
                } = {}
            } = window;
            triggerOnClick(snId);
        });
    }

    restoreScroll(start, maxTime, interval) {
        setTimeout(() => {
            if (!window.hoeksProductId) {
                return;
            }

            const elem = document.querySelector(`.ProductCard_id_${window.hoeksProductId}`)

            if (!elem && Date.now() - start < maxTime) {
                this.restoreScroll(start, maxTime, interval);
                return;
            } else if (!elem) {
                window.hoeksProductId = null;
            }

            if (elem && !this.isElementInViewport(elem)) {
                elem.scrollIntoView(false);
                window.hoeksProductId = null;
            }
        }, interval);
    }

    isElementInViewport(elem) {
        const rect = elem.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
}

export default Router;