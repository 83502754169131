/* eslint-disable */

// imports the original component from vendor folder
import HoeksNavigationTabs from 'HoeksComponent/NavigationTabs/NavigationTabs.component';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';
import Image from 'Component/Image';
import ClerkSearchWrapper from 'Component/ClerkSearchWrapper';

// import new extended styles file
import './NavigationTabs.override.style.scss';

export class NavigationTabs extends HoeksNavigationTabs {
    renderMap = {
        menu: this.renderMenuButton.bind(this),
        search: this.renderSearch.bind(this),
        home: this.renderHomeButton.bind(this),
        account: this.renderAccountButton.bind(this),
        minicart: this.renderMinicartButton.bind(this),
    }

    renderSearch() {
        const { device } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return (
            <div
                block="Header"
                elem="Search"
            >
                <ClerkSearchWrapper />
            </div>
        )
    }

    //To change logo on mobile
    renderHomeButton(isActive = false) {
        const { onHomeButtonClick, header_logo_src } = this.props;
        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        const {
            mix
        } = this.props;

        return (
            <button
                key="home"
                block="NavigationTabs"
                elem="Button"
                aria-label="Home"
                onClick={ onHomeButtonClick }
                className="NavigationTabs-LogoBtn"
            >
                <div
                    block="Header"
                    elem="Button"
                    mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                    mods={ { type: 'home', isVisible: true } }
                    className="NavigationTabs-LogoWrapper"
                >
                    <Image
                        imageRef={this.imageRef}
                        src="/media/logo/svg/picment-logo-sort-220.svg"
                        // srcSet={sources}
                        // sizes={sizes}
                        alt="Picment logo"
                        ratio="custom"
                        mix={{ block: 'NavigationTabs', elem: 'LogoImg', mix }}
                        width="140"
                        height="24"
                    />
                </div>
            </button>
        );
    }
}

export default NavigationTabs;
