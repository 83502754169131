/* eslint-disable */
import ClerkSearch from '@hoeks/clerk/src/component/ClerkSearch';
import ClickOutside from 'Component/ClickOutside';

import './ClerkSearchWrapper.override.style';

/** @namespace Component/ClerkSearchWrapper/Component */
export class ClerkSearchWrapper extends PureComponent {
    renderSearchIcon() {
        const { isActive, onClick } = this.props;

        return (
            <div block="ClerkSearchWrapper" elem="Button" mods={ { isActive } } onClick={ onClick }>
                Search
            </div>
        );
    }

    renderClerkSearch() {
        const { isActive } = this.props;

        return (
            <div block="ClerkSearchWrapper" elem="Search" mods={ { isActive } }>
                <ClerkSearch isVisible={ true } overlay={ true } />
            </div>
        );
    }

    render() {
        const { onClickOutside } = this.props;

        return (
            <ClickOutside onClick={ onClickOutside }>
                { this.renderSearchIcon() }
                { this.renderClerkSearch() }
            </ClickOutside>
        );
    }
}

export default ClerkSearchWrapper;