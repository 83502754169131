/* eslint-disable */

// imports the original component from vendor folder
import {ClerkSearchContainer as SourceClerkSearchContainer, mapStateToProps, mapDispatchToProps} from '@hoeks/clerk/src/component/ClerkSearch/ClerkSearch.container';
import {connect} from 'react-redux';

export * from '@hoeks/clerk/src/component/ClerkSearch/ClerkSearch.container';


export default connect(mapStateToProps, mapDispatchToProps)(SourceClerkSearchContainer);
