/* eslint-disable */
import { ProductCardContainer as HoeksProductCardContainer, mapStateToProps, mapDispatchToProps } from 'HoeksComponent/ProductCard/ProductCard.container';
import { connect } from 'react-redux';
export * from 'HoeksComponent/ProductCard/ProductCard.container';

/** @namespace Component/ProductCard/Container */
export class ProductCardContainer extends HoeksProductCardContainer {
    _getAvailableVisualOptions() {
        const { product: { configurable_options = {} } } = this.props;

        if (Object.keys(configurable_options).length === 0) {
            return [];
        }

        // Find first option that has swatch_data in attribute_options property
        const optionWithSwatchData = Object.values(configurable_options).find((option) => {
            const { attribute_options = {} } = option;

            return Object.values(attribute_options).filter(({ swatch_data }) => swatch_data !== null).some(({ swatch_data, swatch_data: { type, value } = {} } = {}) => swatch_data && type && value);
        });

        const { attribute_options = {} } = optionWithSwatchData || {};

        return Object.values(attribute_options).reduce(
            (acc, option) => {
                const {
                    swatch_data,
                    label
                } = option;

                const { type, value } = swatch_data || {};

                if (type && value) {
                    acc.push({ value, label, type });
                }

                return acc;
            },
            []
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);