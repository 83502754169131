import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';

import Clerk from './Clerk.component';
import { CLERK_ENABLED, LINK_TYPE, CLERK_KEY } from './Clerk.config';
import { getIndexedClerkProduct, prepareRequest, processLinked, processLinkedData } from '../../util/Clerk';
import { callApi } from '../../util/Clerk/Api';

/** @namespace Hoeks/Clerk/Component/Clerk/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    enabled: state.ConfigReducer[CLERK_ENABLED],
    apikey: state.ConfigReducer[CLERK_KEY],
    clerkScriptLoaded: state.ConfigReducer.clerk_script_loaded,
    defaultCurrency: state.ConfigReducer.default_display_currency_code
});

/** @namespace Hoeks/Clerk/Component/Clerk/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Hoeks/Clerk/Component/Clerk/Container/ClerkContainer */
export class ClerkContainer extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool,
        title: PropTypes.string.isRequired,
        variant: PropTypes.string.isRequired,
        labels: PropTypes.arrayOf(PropTypes.string),
        slidesToShow: PropTypes.number,
        apikey: PropTypes.string,
        template: PropTypes.string
    };

    static defaultProps = {
        slidesToShow: 4
    }

    state = {
        areDetailsLoaded: false,
        linkedProducts: { [LINK_TYPE]: {total_count: 0, items: []} },
        linkType: LINK_TYPE,
        numberOfProductsToDisplay: 12
    }

    increment = 0;

    containerFunctions = {
    };

    containerProps() {
        const {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        } = this.state;

        return {
            productCardFunctions: {
                setSiblingsHaveBrands: () => this.setState({ siblingsHaveBrands: true }),
                setSiblingsHavePriceBadge: () => this.setState({ siblingsHavePriceBadge: true }),
                setSiblingsHaveTierPrice: () => this.setState({ siblingsHaveTierPrice: true }),
                setSiblingsHaveConfigurableOptions: () => this.setState({ siblingsHaveConfigurableOptions: true }),
                onActiveImageChange: (id) => this.setState({ activeId: id })
            },
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions,
                isClerkProduct: true
            },
            ...this.props,
            ...this.state
        };
    }

    componentDidMount() {
        this.loadRecommendations();
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        const { data: prevData } = prevProps;
        if (JSON.stringify(data) !== JSON.stringify(prevData)) {
            this.loaded = false;
        }
        this.loadRecommendations();
    }


    loadRecommendations() {
        const { enabled, apikey } = this.props;
        if (!this.loaded && enabled && apikey) {
            this.loaded = true;
            if (!this.fetchData()){
                this.loaded = false;
            }
        }
    }

    async fetchData() {
        const { variant, title, labels, data = {}, apikey } = this.props;
        if (!apikey || !this.validateData(data)) return false;
        const { numberOfProductsToDisplay } = this.state;
        this.increment++;
        const payload = this.getPayload();
        callApi(
            variant, 
            payload,
            this.handleResponse,
            function (response) {
                console.error(response);
            },
            apikey
            );
        return true;
    }

    getPayload(){
        const { title, labels, data = {} } = this.props;
        const { numberOfProductsToDisplay } = this.state;
        const payload = data.template ? 
        {
            attributes: ['id', 'name', 'price', 'image', 'url', 'sku', 'list_price', 'on_sale'],
            labels: labels || [title],
            ...data
        }
            :  
        {
            limit: numberOfProductsToDisplay,
            attributes: ['id', 'name', 'price', 'image', 'url', 'sku', 'list_price', 'on_sale'],
            labels: labels || [title],
            ...data
        };
        return payload;

    }

    validateData(data) {
        const keys = Object.keys(data);
        for (var i = 0; i < keys.length ;i++){
            const value = data[keys[i]];
            if (typeof value === 'undefined') {
                return false;
            }
            if (Array.isArray(value)) {
                if (!this.validateData(value)){
                    return false;
                }
            }
        }
        return true;
    }


    handleResponse = (response) => {
        const { defaultCurrency } = this.props;
        if (response.status == 'error') return;
        const skus = response.product_data.map(item => item.sku);
        const indexedItems = response.product_data.map((item) => getIndexedClerkProduct(item, defaultCurrency));
        this.setState({ linkedProducts: processLinked(indexedItems, skus, true), areDetailsLoaded: true });
        const increment = this.increment;

        executeGet(prepareQuery(prepareRequest(skus)), 'ClerkProducts', 86400)
            .then((result) => this.onSuccess(result, skus, indexedItems, increment))
            .catch(
                (e) => console.log('error', 'Error fetching NewProducts!', e)
            );
    }

    onSuccess(data, skus, indexedItems, increment) {
        if (this.increment !== increment){
            return;
        } 
        const processedItems = processLinkedData(data, skus, indexedItems);
        this.setState({ linkedProducts: processedItems, areDetailsLoaded: true });
        return processedItems;
    }



    render() {
        return (
            <Clerk
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClerkContainer);
