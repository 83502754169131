/* eslint-disable */

export const renderWrapper = (args, callback, instance) => {
    const {
        item: {
            product: {
                attributes: {
                    hoeks_is_sample_product: {
                        attribute_value: isSample = false
                    } = {}
                } = {}
            } = {}
        } = {}
    } = instance.props;

    if (isSample) {
        return (
            <span block="CartItem" elem="Link">
                { instance.renderWrapperContent() }
            </span>
        );
    }

    return callback.apply(instance, args);
}

export const renderImage = (args, callback, instance) => {
    const {
        item: {
            product: {
                attributes: {
                    hoeks_is_sample_product: {
                        attribute_value: isSample = false
                    } = {}
                } = {}
            } = {}
        } = {}
    } = instance.props;

    if (isSample) {
        return instance.renderImageElement();
    }

    return callback.apply(instance, args);
}

export default {
    'Component/CartItem/Component': {
        'member-function': {
            renderWrapper,
            renderImage
        }
    }
}