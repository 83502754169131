/* eslint-disable */
import { appendWithStoreCode, generateQuery, getQueryParam, convertKeyValuesToQueryString, convertQueryStringToKeyValuePairs } from 'Util/Url';

const clickHandler = (args, callback, instance) => {
    const [ e ] = args;

    const {
        attribute,
        onClick,
        parameters,
        attribute: {
            attribute_code,
            attribute_value
        } = {},
        parameters: {
            [attribute_code]: parameterValue = ''
        } = {},
        location: {
            pathname
        } = {},
        product: {
            url
        } = {},
        location,
        history: propHistory,
        product
    } = instance.props;

    if (parameterValue !== attribute_value) {
        return callback.apply(instance, args);
    }

    const getPropHistory = () => {
        if (getQueryParam(attribute_code, location) !== false) {
            const keyValuePairs = convertQueryStringToKeyValuePairs(propHistory.location.search);
            const updatedKeyValuePairs = Object.fromEntries(Object.entries(keyValuePairs).filter(([key]) => key !== attribute_code));
            const updatedQuery = convertKeyValuesToQueryString(updatedKeyValuePairs);
            const search = updatedQuery.length ? `?${updatedQuery}` : '';

            return {
                ...propHistory,
                location: {
                    ...propHistory.location,
                    search
                }
            }
        }

        return propHistory;
    }

    const query = generateQuery(Object.fromEntries(Object.entries(parameters).filter(([key]) => key !== attribute_code)), location, getPropHistory());

    const changeProduct = () => {
        e.preventDefault();
        onClick(attribute, {
            pathname: appendWithStoreCode(url),
            state: { product },
            search: query
        });
    }

    return pathname !== url ? changeProduct() : callback.apply(instance, args);
}

export default {
    'Component/ProductAttributeValue/Component': {
        'member-function': {
            clickHandler
        }
    }
}