import { PureComponent } from 'react';
import ProductCard from 'Component/ProductCard';
import TextPlaceholder from 'Component/TextPlaceholder';
export class ClerkPlaceholder extends PureComponent {
    static defaultProps = {
        numberOfPlaceholders: 4
    }

    renderCards() {
        const { numberOfPlaceholders } = this.props;

        return Array.from(
            { length: numberOfPlaceholders },
            (_, i) => (
                <ProductCard
                    key={i}
                    product={{}}
                />
            )
        );
    }

    renderHeading() {
        return (
            <h3 block="Clerk" elem="Title">
                <TextPlaceholder></TextPlaceholder>
            </h3>
        );
    }

    render() {
        return (
            <div block="ProductList">
                {this.renderHeading()}
                <ul block="ProductListPage">

                    {this.renderCards()}
                </ul>
            </div>
        );

    }
}

export default ClerkPlaceholder;