/* eslint-disable */
const addConfig = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        'clerk_pwa_enabled',
        'clerk_search_enabled',
        'clerk_livesearch_template',
        'clerk_livesearch_suggestions',
        'clerk_livesearch_categories',
        'clerk_api_key'
    ]
}

export default {
    'Query/Config': {
        'member-function': {
            '_getStoreConfigFields': addConfig
        }
    }
}