import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Scripts from './Scripts';
import Event, { EVENT_GTM_PURCHASE } from '@scandipwa/gtm/src/util/Event';
import BrowserDatabase from 'Util/BrowserDatabase';
import { KEY_CONFIG, callApi } from '../../util/Clerk/Api';

export const CLERK_INJECTION_TIMEOUT = 300;

/** @namespace Hoeks/Clerk/Component/ClerkScript/Component/ClerkScriptComponent */
export class ClerkScriptComponent extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool,
        apikey: PropTypes.string
    };

    componentDidMount() {
        const { apikey } = this.props;
        BrowserDatabase.setItem(apikey, KEY_CONFIG);
        this.initialize();
    }

    componentDidUpdate(prevProps) {
        const { apikey } = this.props;
        const { apikey: prevKey } = prevProps;
        if (apikey != prevKey) {
            BrowserDatabase.setItem(apikey, KEY_CONFIG);
        }
        this.initialize();
    }

    initialize() {
        const { enabled, apikey } = this.props;
        if (this.enabled || !apikey || !enabled) {
            return;
        }
        this.enabled = true;
        // this.injectScripts();
        this.bindEvents();
    }

    bindEvents() {
        this.listener = Event.observer(EVENT_GTM_PURCHASE, ({ orderID: orderId, totals }) => {
            this.dispatchPurchaseEvent(
                orderId,
                totals
            );
        });
    }

    dispatchPurchaseEvent(orderId, totals) {
        const { items } = totals;
        const { customer: { email } } = this.props;
        const products = items.map(i => {
            return { id: i.product?.id, price: i.price, quantity: i.qty };
        });
        callApi(
            "log/sale",
            {
                sale: orderId,
                products: products,
                email: email
            },
            function (response) {
            },
            function (response) {
                console.error(response);
            }
        );
    }

    injectScripts() {
        const { apikey } = this.props;
        const { setScriptState } = this.props;
        setScriptState(false);
        const script = Scripts.getScript(apikey);
        setTimeout(() => {
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(script, s);
            setScriptState(true);
        }, CLERK_INJECTION_TIMEOUT);
    }

    render() {
        return null;
    }
}

export default ClerkScriptComponent;
