/* eslint-disable */
import CategoryNavigator from '../component/CategoryNavigator';


const renderCategoryNavigator = (args, callback, instance) => {
    const {
        category,
        categoryIds
    } = instance.props;

    if (!instance.displayProducts()) {
        return null;
    }

    return (
        <CategoryNavigator
            category={ category }
            categoryIds={ categoryIds }
        />
    );
}

const renderFilterOverlay = (args, callback, instance) => {
    return (
        <>
            { instance.renderCategoryNavigator() }
            { callback.apply(instance, args) }
        </>
    );
}

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            renderCategoryNavigator,
            renderFilterOverlay
        }
    }
}