import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import { Field } from 'Util/Query';
import BrowserDatabase from 'Util/BrowserDatabase';

const addTrustpilotConfigFields = (args, callback, instance) => {
    const trustpilot = new Field('trustpilot')
    .addFieldList(['enabled','businessunit_id', 'language']);
    return [
        ...callback(args),
        trustpilot
    ];
}

const addTrustpilotToConfigReducerInitialState = (args, callback, instance) => {
    const { storeConfig: { trustpilot = {} } } = BrowserDatabase.getItem('config') || { storeConfig: { trustpilot: {}} };
    return {
        ...callback(...args),
        trustpilot
    };
};


const addTrustpilotToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { storeConfig: { trustpilot = {} } } = { storeConfig: { trustpilot: {}} }, type } = action;

    if (type !== UPDATE_CONFIG || !Object.keys(trustpilot).length) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        trustpilot
    };
};

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: addTrustpilotConfigFields
        }
    },
    'Store/Config/Reducer/getInitialState': {
        'function': addTrustpilotToConfigReducerInitialState
    },
    'Store/Config/Reducer': {
        'function': addTrustpilotToConfigUpdate
    },
};
