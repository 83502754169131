/* eslint-disable */
import { UPDATE_COLOR_SWATCH } from './ColorSwatch.action';

/** @namespace Store/ColorSwatch/Reducer/getInitialState */
export const getInitialState = () => ({
    colorCollections: []
})

/** @namespace Store/ColorSwatch/Reducer/ColorSwatchReducer */
export const ColorSwatchReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        colorCollections
    } = action;

    switch (type) {
        case UPDATE_COLOR_SWATCH:
            // newColorCollectionNames = colorCollections.map(colorCollection => colorCollection.name);
            // state.colorCollections.filter(colorCollection => !newColorCollectionNames.includes(colorCollection.name))

            return {
                ...state,
                colorCollections: [
                    ...state.colorCollections.filter(colorCollection => !colorCollections.map(colorCollection => colorCollection.name).includes(colorCollection.name)),
                    ...colorCollections.map(({ name, code, colors }) => ({ name, code, colors: [...new Set(colors.map(JSON.stringify))].map(JSON.parse) }))
                ]
            };
        default:
            return state;
    }
};

export default ColorSwatchReducer;