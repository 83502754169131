/* eslint-disable */
import { CategoryQuery as HoeksCategoryQuery } from 'HoeksQuery/Category.query';

/** @namespace Query/Category */
export class CategoryQuery extends HoeksCategoryQuery {
    _getDefaultFields() {
        return [
            ...super._getDefaultFields(),
            'category_menu',
            'title'
        ];
    }
}

export default new CategoryQuery();