/* eslint-disable */

// imports the original component from vendor folder
import SourceColorSwatchPicker from '@hoeks/color-swatch/src/component/ColorSwatchPicker/ColorSwatchPicker.component';

import Loader from 'Component/Loader';
import Field from 'Component/Field';
import { TEXT_TYPE } from 'Component/Field/Field.config';

// import new extended styles file
import './ColorSwatchPicker.override.style.scss';

export class ColorSwatchPicker extends SourceColorSwatchPicker { 

    renderInput() {
        const {
            fieldValue = '',
            placeholderValue = __('Click the button above to select a color'),
            option: {
                option_id
            } = {},
            onColorSelect
        } = this.props;

        return (
            <div 
                block="ColorSwatchPicker"
                elem="ColorCodeContainer"
            >
                <span 
                    block="ColorSwatchPicker"
                    elem="ColorCodeLabel"
                >
                    { __('Chosen color:') }
                </span>
                <Field
                    type={ TEXT_TYPE }
                    name={ `color-${option_id}` }
                    id={ `color-${option_id}` }
                    // isDisabled={ true }
                    value={ fieldValue }
                    placeholder={ placeholderValue }
                    onChange={ val => onColorSelect(val) }
                />
            </div>
        )
    }

    renderColorTabs = () => {
        const {
            colorCollections,
            onCollectionSelect,
            selectedCollection
        } = this.props;

        if (!colorCollections.length) {
            return (
                <Loader isLoading={ true } />
            )
        }

        return (
            <>
                <div
                    block="ColorSwatchPicker"
                    elem="CollectionButtonContainer"
                >
                        {/* <button
                            onClick={ () => onCollectionSelect(null) }
                            block="ColorSwatchPicker"
                            elem="CollectionButton"
                            mods={ { type: 'all', isActive: selectedCollection === null } }
                            mix={ { block: 'Button' } }
                        >
                            { __('All') }
                        </button> */}
                    { colorCollections.map(this.renderColorTab) }
                </div>
                { colorCollections.map(this.renderColorList) }
            </>
        )
    }
}


export default ColorSwatchPicker; 
