/* eslint-disable */
import { ProductListQuery as HoeksProductListQuery } from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

export * from 'SourceQuery/ProductList.query';

/**
 * Product List Query
 * @class ProductListQuery
 * @namespace Query/ProductList
 */
export class ProductListQuery extends HoeksProductListQuery {
  _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
    return [
      ...super._getProductInterfaceFields(isVariant, isForLinkedProducts),
      this._getProductSmallSdField(),
      'range_num',
      'parts',
      'height',
      'width',
      'stuk_diameter',
      'width_unit_frontend',
      'length_m',
      'length_cm',
      'repeat',
      'size_liter',
      'detale_instruction_guide',
      'foil_instruction_guide',
      'paint_instruction_guide',
      'panels_instruction_guide',
      'stuk_instruction_guide',
      'wallpaper_instruction_guide',
      'paint_symbols',
      'faq',
      'only_x_left_in_stock',
      'custom_image_minimum_price',
      'old_sku', 
      'manufacturer_sku'
    ];
  }

  _getProductSmallSdField() {
    return new Field('small_image_sd')
        .addFieldList(this._getProductSmallFields());
  }
}

export default new ProductListQuery();