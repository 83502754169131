/* eslint-disable */
import Popup from 'Component/Popup';
import Link from 'Component/Link';
import { DEPARTMENT_MENU_POPUP_ID } from './DepartmentMenu.config';
import CmsBlock from 'Component/CmsBlock';
import './DepartmentMenu.style';

/** @namespace Component/DepartmentMenu/Component */
export class DepartmentMenu extends PureComponent {
    renderMenuButton() {
        const { onMenuClick } = this.props;
        return (
            <button
                key="departmentMenu"
                block="DepartmentMenu"
                elem="Button"
                onClick={ onMenuClick }
            >
                <div
                    block="DepartmentMenu"
                    elem="Title"
                >
                    { __('Department menu') }
                </div>
                <div
                    block="DepartmentMenu"
                    elem="Icon"
                />
            </button>
        );
    }

    renderContent() {
        return (
            <Popup
                id={ DEPARTMENT_MENU_POPUP_ID }
                clickOutside={ false }
                mix={ { block: 'DepartmentMenuPopup' } }
            >
                { this.renderDepartments() }
                <div 
                    block="DepartmentMenu"
                    elem="BottomMenuContainer"
                >
                    <div 
                        block="DepartmentMenu"
                        elem="BottomMenu"
                        mods={{ type: 'left' }}
                    >
                        <CmsBlock identifier="department-menu-bottom-left" />
                    </div>
                    <div 
                        block="DepartmentMenu"
                        elem="BottomMenu"
                        mods={{ type: 'center' }}
                    >
                        <CmsBlock identifier="department-menu-bottom-center" />
                    </div>
                    <div 
                        block="DepartmentMenu"
                        elem="BottomMenu"
                        mods={{ type: 'right' }}
                    >
                        <CmsBlock identifier="department-menu-bottom-right" />
                    </div>
                </div>
            </Popup>
        );
    }

    renderDepartments() {
        const { menu } = this.props;
        const items = Object.values(menu).reduce((acc, { children }) => {
            return [ ...acc, ...Object.values(children) ];
        }, []).filter(({ is_active, item_class }) => is_active && item_class.includes('afdelingsside')).sort(({ position: posA }, { position: posB }) => posA - posB);

        return (
            <div
                block="DepartmentMenu"
                elem="MenuList"
            >
                { items.map(this.renderDepartment) }
            </div>
        );
    }

    renderDepartment = (menuItem) => {
        const {
            title,
            url
        } = menuItem;

        return (
            <div
                block="DepartmentMenu"
                elem="MenuItem"
                key={ title }
            >
                <Link
                    block="DepartmentMenu"
                    elem="MenuItemLink"
                    to={ url }
                >
                    { title }
                </Link>
            </div>
        )
    }

    render() {
        /* VIC: Department menu was never shown on SE */
        return (
            <>
                {/* { this.renderMenuButton() } */}
                { this.renderContent() }
            </>
        );
    }
}

export default DepartmentMenu;