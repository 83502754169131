/* eslint-disable */

// imports the original component from vendor folder
import SourceBreadcrumbs from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';

// import new extended styles file
import './Breadcrumbs.override.style.scss';

export class Breadcrumbs extends SourceBreadcrumbs { 

}

export default Breadcrumbs;
