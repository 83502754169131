/* eslint-disable */
import { MyAccountQuery as HokesMyAccountQuery } from 'HoeksQuery/MyAccount.query';

/**
 * @class MyAccount
 * @namespace Query/MyAccount
 */
export class MyAccountQuery extends HokesMyAccountQuery {
    _getAddressFields() {
        return [
            ...super._getAddressFields(),
            'company'
        ];
    }
}

export default new MyAccountQuery();