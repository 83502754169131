/* eslint-disable */

// imports the original component from vendor folder
import HoeksCurrencySwitcher from 'HoeksComponent/CurrencySwitcher/CurrencySwitcher.component';

export class CurrencySwitcher extends HoeksCurrencySwitcher { 

    render() {
        return(
            <></>
        );
    }

}

export default CurrencySwitcher;
