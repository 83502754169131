/* eslint-disable */
import FieldInput from 'Component/FieldInput';

const renderTypeNumber = (args, callback, instance) => {
    const {
        min,
        max,
        value,
        onKeyEnterDown,
        handleChange,
        readOnly
    } = instance.props;

    return (
        <>
            <FieldInput
              { ...instance.props }
              type="number"
              readOnly={ readOnly }
              // eslint-disable-next-line react/jsx-no-bind
              onChange={ (e) => handleChange(e.target.value) }
              onKeyDown={ onKeyEnterDown }
              aria-label={ __('Value') }
            />
            <button
              disabled={ +value === max }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleChange(+value + 1) }
              aria-label={ __('Add') }
            />
            <button
              disabled={ +value === min }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleChange(+value - 1) }
              aria-label={ __('Subtract') }
            />
        </>
    );
}

const defaultProps = (member) => ({
    ...member,
    readOnly: false
})

const containerProps = (args, callback, instance) => {
    const {
        readOnly
    } = instance.props;

    return {
        ...callback.apply(instance, args),
        readOnly
    }
}

export default {
    'Component/Field/Component': {
        'member-function': {
            renderTypeNumber
        }
    },
    'Component/Field/Container': {
        'static-member': {
            defaultProps
        },
        'member-function': {
            containerProps
        }
    }
}