/* eslint-disable */

const componentDidUpdate = (args, callback, instance) => {
    callback.apply(instance, args);

    const [ prevProps ] = args;
    const { props } = instance;

    const {
        identifier: prevIdentifier
    } = prevProps;

    const {
        identifier
    } = props;

    if (identifier && prevIdentifier !== identifier) {
        instance._getCmsBlock();
    }
}

export default {
    'Component/CmsBlock/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
}