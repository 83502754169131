/* eslint-disable */
import HoeksCmsBlockContainer from 'HoeksComponent/CmsBlock/CmsBlock.container';
import CmsBlock from './CmsBlock.component';

/** @namespace Component/CmsBlock/Container */
export class CmsBlockContainer extends HoeksCmsBlockContainer {
    themeContainerProps = () => {
        const { children } = this.props;

        return {
            ...this.containerProps(),
            children
        }
    }

    render() {
        return (
            <CmsBlock
              { ...this.themeContainerProps() }
            />
        );
    }
}

export default CmsBlockContainer;