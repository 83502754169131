/* eslint-disable */
import {
    isMobile,
    isMobileClientHints,
    isUsingClientHints
} from 'Util/Mobile';

const addType = async (args, callback, instance) => {
    const { updateConfigDevice } = instance.props;
    if (isUsingClientHints) {
        const { platform, model } = await isMobileClientHints.getDeviceData();
        updateConfigDevice({
            isMobile: isMobile.any(),
            android: isMobile.android(platform),
            ios: isMobile.iOS(platform),
            blackberry: isMobile.blackBerry(model),
            opera: isMobile.opera(model),
            safari: isMobile.safari(model),
            windows: isMobile.windows(model),
            small: isMobile.small(model),
        });
    } else {
        updateConfigDevice({
            isMobile: isMobile.any(),
            android: isMobile.android(),
            ios: isMobile.iOS(),
            blackberry: isMobile.blackBerry(),
            opera: isMobile.opera(),
            safari: isMobile.safari(),
            windows: isMobile.windows(),
            small: isMobile.small()
        });
    }
}

export default {
    'Component/Router/Container': {
        'member-function': {
            handleResize: addType
        }
    }
}
