import { Field } from 'Util/Query';

/* eslint-disable */
const addFields = (args, callback, instance) => {
    const productField = new Field('product');
    productField.addField('id');
    return [
        ...callback.apply(instance, args),
        productField
    ];
}

export default {
    'Query/Checkout': {
        'member-function': {
            '_getTotalItemFields': addFields
        }
    }
}