import {SearchItemContainer, mapStateToProps, mapDispatchToProps} from 'Component/SearchItem/SearchItem.container';
import ClerkSearchItem from './ClerkSearchItem.component';
import { connect } from 'react-redux';
import { callApi } from '../../util/Clerk/Api';

export class ClerkSearchItemContainer extends SearchItemContainer {
    containerFunctions = {
        onClick: this.handleItemClick.bind(this)
    };

    handleItemClick() {
        const { product: { id }, onClick } = this.props;
        callApi(
            'log/click',
            { product: id},
            function(response){},
            console.log
        );
        onClick();
    }

    render() {
        return (
            <ClerkSearchItem
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClerkSearchItemContainer);
