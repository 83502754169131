/* eslint-disable */
import ClerkEvent from '@hoeks/clerk/src/component/ClerkEvent';
import { EVENT_GTM_PRODUCT_ADD_TO_CART } from '@scandipwa/gtm/src/util/Event';
import getStore from 'Util/Store';

class ProductPagePlugin {
    addComplementary = (args, callback, instance) => {
        const { product: { id } } = instance.props;
        const state = getStore().getState();
        const {
            ConfigReducer: {
                theme_clerk_complementary_template
            } = {}
        } = state || {};

        if (!theme_clerk_complementary_template) {
            return callback.apply(instance, args);
        }

        return (
            <>
                <div block="ProductPage" elem="AddToCartPromotion">
                    {id ? <ClerkEvent event={EVENT_GTM_PRODUCT_ADD_TO_CART} title={__("You might also like")} variant="recommendations/visitor/complementary" data={{ template: theme_clerk_complementary_template }} /> : null}
                </div>
                {callback(...args)}
            </>
        );
    }

    aroundAfterAddToCart = (args, callback, instance) => {
        return callback(...args);
    }

}

const { addComplementary, aroundAfterAddToCart } = new ProductPagePlugin();



const showComplementary = () => {
    this.setState({ hasComplementary: true });
}

const aroundContainerFunctions = (member, instance) => {
    return {
        ...member,
        showComplementary: showComplementary.bind(instance)
    }
}

export default {
    'Component/AddToCart/Container': {
        'member-function': {
            'afterAddToCart': aroundAfterAddToCart
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            // 'renderProductPageContent': aroundRenderProductPageContent,
            'render': addComplementary
        }
    },
    'Route/ProductPage/Container': {
        'member-property': {
            'containerFunctions': aroundContainerFunctions
        }
    }
}