class Scripts {
    static getScript(apikey) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `(function(w,d){
            var e=d.createElement('script');e.type='text/javascript';e.async=true;
            e.src=(d.location.protocol=='https:'?'https':'http')+'://cdn.clerk.io/clerk.js';
            var s=d.getElementsByTagName('script')[0];s.parentNode.insertBefore(e,s);
            w.__clerk_q=w.__clerk_q||[];w.Clerk=w.Clerk||function(){w.__clerk_q.push(arguments)};
          })(window,document);
      
          Clerk('config', {
            key: '${apikey}'
          });`;
        if (typeof(script.async) != "undefined") { script.async = true; }
        return script;
    }
}

export default Scripts;