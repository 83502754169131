// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import EanFields from './EanFields.component';

/** @namespace Hoeks/Tricean/Component/EanFields/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Hoeks/Tricean/Component/EanFields/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Hoeks/Tricean/Component/EanFields/Container/EanFieldsContainer */
export class EanFieldsContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    componentDidMount() {
        const { setOrderButtonEnableStatus, ean_number = '', company = '', req_number = '', reference = '' } = this.props;
        if (ean_number.length !== 13 || company.length <= 0 || req_number.length <= 0 || reference.length <= 0) {
            setOrderButtonEnableStatus(false);
        }
    }

    componentDidUpdate() {
        const { setOrderButtonEnableStatus, ean_number = '', company = '', req_number = '', reference = '' } = this.props;
        if (ean_number.length === 13 && company.length > 0 && req_number.length > 0 && reference.length > 0) {
            setOrderButtonEnableStatus(true);
        } else {
            setOrderButtonEnableStatus(false);
        }
    }

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        return { ...this.props }
    };

    render() {
        return (
            <EanFields
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EanFieldsContainer);
