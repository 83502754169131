import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Scripts from './Scripts';

export const TRUSTPILOT_INJECTION_TIMEOUT = 500;

export class TrustpilotScript extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool
    }

    initialize() {
        const { enabled } = this.props;
        if (this.enabled || !enabled) {
            return;
        }
        this.enabled = true;
        this.injectScripts();
    }

    static instance = null;

    componentDidMount() {
        TrustpilotScript.instance = this;
    }

    injectScripts() {
        const script = Scripts.getScript(); 
        setTimeout(() => {
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(script, s);
        }, TRUSTPILOT_INJECTION_TIMEOUT);
    }

    render() {
        return null;
    }
}

export default TrustpilotScript;
