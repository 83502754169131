/* eslint-disable */

import {
    UPDATE_URL_REWRITE
} from 'Store/UrlRewrites/UrlRewrites.action';

const UrlRewritesReducer = (args, callback, context) => {
    const [state, action] = args;

    if (action.type === UPDATE_URL_REWRITE) {
        const {
            urlRewrite: {
                id = null
            } = {}
        } = action;

        if (id !== undefined && id !== null && typeof(id) === 'string') {
            const updatedAction = {
                ...action,
                urlRewrite: {
                    ...action?.urlRewrite,
                    id: parseInt(id)
                }
            }

            return callback(state, updatedAction);
        }
    }


    return callback(...args);
}

export default {
    'Store/UrlRewrites/Reducer': {
        'function': UrlRewritesReducer
    }
}