import { lazy } from 'react';

import SourceWidgetFactory from 'SourceComponent/WidgetFactory/WidgetFactory.component';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import Fallback from 'Component/Fallback';

export const VideoWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "video-widget" */ '../Video'));

const NEWSLETTER = 'Newsletter';
const HOEKS_VIDEO_WIDGET = 'HoeksVideoWidget';

/** @namespace Component/WidgetFactory/Component */
export class WidgetFactory extends SourceWidgetFactory {
    renderMap = {
        ...this.renderMap,
        [NEWSLETTER]: {
            component: NewsletterSubscription
        },
        [HOEKS_VIDEO_WIDGET]: {
            component: VideoWidget,
            fallback: (props) => <Fallback { ...props } />
        }
    }

    renderContent() {
        const { type } = this.props;
        const {
            component: Widget,
            fallback
        } = this.renderMap[type] || {};

        const wrappedFallback = typeof fallback === 'function' ? () => fallback(this.props) : fallback;

        if (Widget !== undefined) {
            return (
                <RenderWhenVisible fallback={ wrappedFallback }>
                    <Widget { ...this.props } />
                </RenderWhenVisible>
            );
        }

        return null;
    }
}

export default WidgetFactory;
