/* eslint-disable */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import Image from 'Component/Image';


export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

export const mapDispatchToProps = (dispatch) => ({
});


export const IMAGE_TYPES = ['png','jpg','jpeg'];

export class CmsImage extends PureComponent {
    getSmallImage(src){
        const split = src.split('.');
        if (split.length > 1){
            split[split.length-2] = split[split.length-2]+'__sm';
        }
         
        return IMAGE_TYPES.includes(split.slice(-1)[0]) ? split.join('.') : src;
    }

    render(){
        const { device, src } = this.props;
        const newSrc = device.small ? this.getSmallImage(src) : src;
        return <Image {...this.props} src={newSrc} />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsImage);
