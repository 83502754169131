/**
 * Trustpilot scripts
 */
 class Scripts {
    static getScript() {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js`;
        if (typeof(script.async) != "undefined") { script.async = true; }
        return script;
    }
}

export default Scripts;
