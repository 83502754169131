/* eslint-disable */
import TextPlaceholder from 'Component/TextPlaceholder';
import Link from 'Component/Link';
import './CategoryNavigator.style';

/** @namespace Component/CategoryNavigator/Component */
export class CategoryNavigator extends PureComponent {
    renderContent() {
        const { isLoaded, categoryId, categoryIds } = this.props;

        if (!isLoaded || categoryIds !== categoryId) {
            return this.renderPlaceholder();
        }

        return (
            <>
                { this.renderTitle() }
                { this.renderCategories() }
            </>
        );
    }

    renderTitle() {
        const { title } = this.props;

        return (
            <span block="CategoryNavigator" elem="Title">{ title }: </span>
        );
    }

    renderCategories() {
        const { categories } = this.props;
        // Victor - kategori - include in menu - filtrering
        // console.log(categories);
        const filteredCategories = categories.filter(category => category.include_in_menu !== 0);
        // console.log(filteredCategories);
        return (
            <span block="CategoryNavigator" elem="CategoryList">
                { filteredCategories.map(this.renderCategory) }
            </span>
        );
    }

    renderCategory = (category) => {
        const { id, url, name } = category;
        const { categoryId } = this.props;

        const to = {
            pathname: url,
            search: "",
            state: {
                category: id
            }
        };

        return (
            <span key={ id } block="CategoryNavigator" elem="Category" mods={ { isSelected: id === categoryId } }>
                <Link to={ to } block="CategoryNavigator" elem="CategoryLink">
                    { name }
                </Link>
            </span>
        )
    }

    renderPlaceholder() {
        return (
            <TextPlaceholder length="custom" mix={ { block: 'CategoryNavigator', elem: 'Placeholder' } }/>
        );
    }

    render() {
        const { isLoaded, categories } = this.props;

        if (isLoaded && !categories.length) {
            return null;
        }

        return (
            <div block="CategoryNavigator" elem="Wrapper">
                { this.renderContent() }
            </div>
        );
    }
}

export default CategoryNavigator;