/* eslint-disable */
import DataContainer from 'Util/Request/DataContainer';
import DepartmentMenu from './DepartmentMenu.component';
import { connect } from 'react-redux';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { DEPARTMENT_MENU_POPUP_ID } from './DepartmentMenu.config';
import MenuHelper from 'Util/Menu';
import MenuQuery from 'Query/Menu.query';

/** @namespace Component/DepartmentMenu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Component/DepartmentMenu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(DEPARTMENT_MENU_POPUP_ID, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Component/DepartmentMenu/Container */
export class DepartmentMenuContainer extends DataContainer {
    state = {
        menu: {}
    }

    componentDidMount() {
        this.getMenu();
    }

    containerFunctions = {
        onMenuClick: this.onMenuClick.bind(this)
    }

    getMenuOptions() {
        const { header_content: { header_menu } = {} } = window.contentConfiguration;

        return {
            identifier: header_menu || 'new-main-menu'
        };
    }

    getMenu() {
        this.fetchData(
            [MenuQuery.getQuery(this.getMenuOptions())],
            ({ menu }) => this.setState({
                menu: MenuHelper.reduce(menu)
            })
        );
    }

    onMenuClick() {
        const { showPopup } = this.props;
        showPopup({
            title: __('Department menu')
        });
    }

    render() {
        return (
            <DepartmentMenu
                { ...this.containerFunctions }
                { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentMenuContainer);