/* eslint-disable */
const _getUrlResolverFields = (args, callback, instance) => ([
    ...callback.apply(instance, args),
    'originalSku'
])

export default {
    'Query/UrlRewrites': {
        'member-function': {
            _getUrlResolverFields
        }
    }
}