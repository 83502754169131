/* eslint-disable */
import { lazy } from 'react';
import HoeksHtml from 'HoeksComponent/Html/Html.component';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { EXPANDABLE_CONTENT } from 'Component/HtmlExpandableContent/HtmlExpandableContent.config';
// import HtmlExpandableContent from 'Component/HtmlExpandableContent';
export const HtmlExpandableContent = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/HtmlExpandableContent'));

export * from 'HoeksComponent/Html/Html.component';

export class Html extends HoeksHtml {
    rules = [
        ...this.rules,
        {
            query: { name: ['div'] },
            replace: this.replaceDiv
        }
    ]

    renderMap = {
        [EXPANDABLE_CONTENT]: {
            component: HtmlExpandableContent
        }
    }

    replaceDiv({ attribs, children }) {
        const {
            class: className = ''
        } = attribs;
        const replaceClass = className.split(' ').find(someClass => this.renderMap[someClass] !== undefined);
        if (replaceClass) {
            const {
                component: ReplaceComponent
            } = this.renderMap[replaceClass];
            return <ReplaceComponent { ...attributesToProps(attribs) } children={ domToReact(children, this.parserOptions) } />
        }

        return <div { ...attributesToProps(attribs) } >{ domToReact(children, this.parserOptions) }</div>;
    }
}

export default Html;