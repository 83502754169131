/* eslint-disable */

// imports the original component from vendor folder
import HoeksProductAttributeValue from 'HoeksComponent/ProductAttributeValue/ProductAttributeValue.component';
import { STRING_ONLY_ATTRIBUTE_CODES } from 'Component/ProductAttributeValue/ProductAttributeValue.config';
import { connect } from 'react-redux';
export * from 'HoeksComponent/ProductAttributeValue/ProductAttributeValue.component';
import { round } from 'Util/Math';
import Html from 'Component/Html';

// import new extended styles file
import './ProductAttributeValue.override.style.scss';

/** @namespace Component/ProductAttributeValue/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    variants: state.ProductReducer.product.variants
});

/** @namespace Component/ProductAttributeValue/Component/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({})

/** @namespace Component/ProductAttributeValue/Component */
export class ProductAttributeValue extends HoeksProductAttributeValue { 
    renderSelectAttribute() {
        const { attribute: { attribute_value, attribute_code } } = this.props;
        const attributeOption = this.getOptionLabel(attribute_value);
        const { count, swatch_data } = attributeOption;
        var { label } = attributeOption;

        if ( count !== undefined && count != "" && label !== undefined) {
            label += '<span class="ProductAttributeValue-Count">(' + count + ')</span>';
        }
        if (!swatch_data || STRING_ONLY_ATTRIBUTE_CODES.includes(attribute_code)) {
            return this.renderStringValue(label || __('N/A'));
        }

        const { value, type } = swatch_data;

        switch (type) {
        case '0':
            return this.renderStringValue(label, label);
        case '1':
            return this.renderColorValue(value, label);
        case '2':
            return this.renderImageValue(value, label);
        case '4':
            return this.renderProductImageValue(value, label);
        default:
            return this.renderStringValue(label || __('N/A'));
        }
    }

    renderProductImageValue(img, label) {
        const { isFormattedAsText, isSelected, variants, attribute: { attribute_value, attribute_code } } = this.props;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            // stylelint-disable-next-line value-keyword-case
            '--option-is-selected': isSelected ? 1 : 0
        };

        const matchingVariant = variants.filter(({ attributes }) => attributes[attribute_code].attribute_value == attribute_value);

        if (matchingVariant.length > 0) {
            const {
                thumbnail: {
                    url = ''
                } = {}
            } = matchingVariant[0];

            return (
                <>
                    <img
                      block="ProductAttributeValue"
                      elem="Image"
                      mods={ { isProductImage: true } }
                      src={ url }
                      alt={ label }
                    />
                    <data
                      block="ProductAttributeValue"
                      elem="Image-Overlay"
                      value={ label }
                      title={ label }
                      style={ style }
                    />
                </>
            );
        }

        return label || __('N/A');
    }

    renderStringValue(value, label) {
        const { isFormattedAsText } = this.props;

        if (isFormattedAsText && !isNaN(label)) {
            return `${round(+label)}`;
        }

        if (isFormattedAsText && !isNaN(value)) {
            return `${round(+value)}`;
        }

        if (typeof value === 'string' && value) {
            return (
                <div
                  block="ProductAttributeValue"
                  elem="TextArea"
                >
                    <Html content={ value } />
                </div>
            );
        }

        return super.renderStringValue(value, label);
    }

    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            isSelected
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (isFormattedAsText) {
            return (
                <div
                  block="ProductAttributeValue"
                  mix={ mix }
                  mods={ { isSelected } }
                >
                    { this.renderAttributeByType() }
                </div>
            );
        }

        return (
            <a
              href={ href }
              block="ProductAttributeValue"
              mods={ { isNotAvailable, isSelected } }
              onClick={ this.clickHandler }
              aria-hidden={ isNotAvailable }
              mix={ mix }
            >
                { this.renderAttributeByType() }
            </a>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttributeValue);
