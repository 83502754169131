/* eslint-disable */
const _getDefaultFields = (args, callback, instance) => ([
    ...callback.apply(instance, args),
    'parent_id'
])

export default {
    'Query/Category': {
        'member-function': {
            _getDefaultFields
        }
    }
}