// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Field from 'Component/Field';

import './EanFields.style';

/** @namespace Hoeks/Tricean/Component/EanFields/Component/EanFieldsComponent */
export class EanFieldsComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };


    onChange = () => {

    }

    renderConent(){
        const {
            onReqChange,
            onEanChange,
            onCompanyChange,
            onReferenceChange,
            ean_number,
            req_number,
            company,
            reference
        } = this.props;

        return (
            <>
                <Field
                    type="text"
                    mix={ { block: 'PaymentField' } }
                    label={ __('EAN Number (required)') }
                    id="ean_number"
                    name="ean_number"
                    validationStatus={['notEmpty', 'eanNumber']}
                    onChange={ onEanChange }
                    value={ ean_number }
                />
                <Field
                    type="text"
                    mix={ { block: 'PaymentField' } }
                    label={ __('Company (required)') }
                    id="company"
                    name="company"
                    validationStatus={['notEmpty']}
                    onChange={ onCompanyChange }
                    value={ company }
                />
                <Field
                    type="text"
                    mix={ { block: 'PaymentField' } }
                    label={ __('Requisition Number (required)') }
                    id="req_number"
                    name="req_number"
                    validationStatus={['notEmpty']}
                    onChange={ onReqChange }
                    value={ req_number }
                />
                <Field
                    type="text"
                    mix={ { block: 'PaymentField' } }
                    label={ __('Reference (required)') }
                    id="reference"
                    name="reference"
                    validationStatus={['notEmpty']}
                    onChange={ onReferenceChange }
                    value={ reference }
                />
            </>
        );
    }

    render() {
        return (
            <div block="EanFields">
                { this.renderConent() }
            </div>
        );
    }
}

export default EanFieldsComponent;
