/* eslint-disable */

import { ProductListContainer as SourceProductListContainer } from "SourceComponent/ProductList/ProductList.container";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ProductListInfoDispatcher from 'Store/ProductListInfo/ProductListInfo.dispatcher';

/** @namespace Component/ProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Component/ProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestProductListInfo: (options) => ProductListInfoDispatcher.handleData(dispatch, options)
});

/** @namespace Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {
    static defaultProps = {
        pageSize: 24,
        filter: {},
        search: '',
        selectedFilters: {},
        sort: undefined,
        isPreventRequest: false,
        isPaginationEnabled: true,
        isInfiniteLoaderEnabled: true,
        isPageLoading: false,
        noAttributes: false,
        noVariants: false,
        isWidget: false
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListContainer));
