/* eslint-disable */
import { CheckoutQuery as SourceCheckoutQuery } from 'SourceQuery/Checkout.query';

/** @namespace Query/Checkout */
export class CheckoutQuery extends SourceCheckoutQuery {
    getPlaceOrderMutation(guestCartId, comment) {
        const mutation = super.getPlaceOrderMutation(guestCartId);
        mutation.addArgument('orderComment', 'String', comment);
        return mutation;
    }
}

export default new CheckoutQuery();
