/* eslint-disable */
import CartQuery from 'Query/Cart.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation, fetchQuery } from 'Util/Request';
export const CHANGE_CUSTOMER_TYPE = 'CHANGE_CUSTOMER_TYPE';

const changeCustomerTypeAndUpdateCartData = (args, callback, instance) => {
    const [dispatch, type, cb] = args;

    fetchMutation(CartQuery.getChangeCustomerTypeMutation(
        !isSignedIn() && getGuestQuoteId(), type
    )).then(
        (result) => {
            dispatch(changeCustomerTypeAction(result));
            fetchQuery(CartQuery.getCartQuery(
                !isSignedIn() && getGuestQuoteId()
            )).then(
                (cartData) => {
                    instance.handle_syncCartWithBESuccess(dispatch, cartData); cb();
                }
            )
        },
        (error) => instance.handle_syncCartWithBEError(dispatch, error)
    );
}

const changeCustomerType = (args, callback, instance) => {
    const [dispatch, type] = args;
    dispatch(changeCustomerTypeAction({ data: type }))
}

export const changeCustomerTypeAction = ({ data }) => ({
    type: CHANGE_CUSTOMER_TYPE,
    customerType: data
});

const reducerWithExistingState = (args, callback, instance) => {
    const [_, state] = args;

    return {
        ...state,
        ...callback.apply(instance, args)
    }
}

const getInitialState = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    customerType: false
})

const CartReducer = (args, callback, instance) => {
    const [state, action] = args;
    const { type } = action;

    switch(type) {
        case CHANGE_CUSTOMER_TYPE:
            const { customerType } = action;
            return {
                ...state,
                customerType
            };
        default:
            return callback.apply(instance, args);
    }
}

const getChangeCustomerTypeMutation = (args, callback, instance) => {
    const [quoteId, type] = args;

    const query = new Field('changeCustomerType')
        .setAlias('data');

    if (!isSignedIn()) {
        query.addArgument('guestCartId', 'String', quoteId);
    }
    query.addArgument('type', 'String', type);

    return query;
}

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            changeCustomerTypeAndUpdateCartData,
            changeCustomerType
        }
    },
    'Store/Cart/Reducer/updateCartTotals': {
        'function': reducerWithExistingState
    },
    'Store/Cart/Reducer/updateShippingPrice': {
        'function': reducerWithExistingState
    },
    'Store/Cart/Reducer/getInitialState': {
        'function': getInitialState
    },
    'Store/Cart/Reducer': {
        'function': CartReducer
    },
    'Query/Cart': {
        'member-function': {
            getChangeCustomerTypeMutation
        }
    }
}