/* eslint-disable */
import { formatPrice } from 'Util/Price';

const mapStateToProps = (args, callback, context) => {
    const [state] = args;
    return {
        ...callback.apply(context, args),
        currency: state.ConfigReducer.default_display_currency_code
    };
}

const getDropdownOptions = (args, callback, instance) => {
    const [ values ] = args;
    const { currency } = instance.props;

    return values.reduce((acc, {
        option_type_id, title, price, price_type
    }) => {
        acc.push({
            id: option_type_id,
            name: title,
            value: option_type_id,
            label: `${title} + ${instance.renderOptionLabel(price_type, price, currency)}`
        });

        return acc;
    }, []);
}

const renderOptionLabel = (args, callback, instance) => {
    const [ priceType, price, currency ] = args;

    switch (priceType) {
        case 'PERCENT':
            return `${ price }%`;
        default:
            return formatPrice(price, currency);
        }
}

export default {
    'Component/ProductCustomizableOption/Container/mapStateToProps': {
        'function': mapStateToProps
    },
    'Component/ProductCustomizableOption/Container': {
        'member-function': {
            getDropdownOptions,
            renderOptionLabel
        }
    }
}