/* eslint-disable */
const _getStoreConfigFields = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        'theme_clerk_complementary_template'
    ];
}

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields
        }
    }
}