/* eslint-disable */

// imports the original component from vendor folder
import { ClerkSearchComponent as SourceClerkSearch } from '@hoeks/clerk/src/component/ClerkSearch/ClerkSearch.component';

// import new extended styles file
import './ClerkSearch.override.style.scss';

export class ClerkSearch extends SourceClerkSearch {
    renderInput() {
        const { searchCriteria, onFocus } = this.props;
        return (
            <div block="ClerkSearch" elem="InputWrapper">
                <input
                    id="search-field"
                    block="ClerkSearch"
                    elem="Input"
                    onChange={ this.handleChange }
                    onKeyDown={ this.onSearchEnterPress }
                    value={ searchCriteria }
                    mods={ { isActive: true } }
                    autoComplete="off"
                    aria-label={ __('Search') }
                    onFocus={ onFocus }
                    placeholder={ __('Search 10,000 products') }
                />
                <span block="ClerkSearch" elem="Input-Icon"></span>
            </div>
        );
    }
}

export default ClerkSearch;
