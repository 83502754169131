/* eslint-disable */

// imports the original component from vendor folder
import SearchItem from 'Component/SearchItem/SearchItem.component';

import TextPlaceholder from 'Component/TextPlaceholder';

import './ClerkSearchItem.override.style.scss';
import { formatPrice, roundPrice } from 'Util/Price';

export class ClerkSearchItem extends SearchItem { 

    renderContent() {
        const { product: { name, price_range: { minimum_price: { final_price: { currency, value: price } }} } } = this.props;

        return (
            <figcaption block="SearchItem" elem="Content">
                { this.renderCustomAttribute() }
                <h4 block="SearchItem" elem="Title" mods={ { isLoaded: !!name } }>
                    <TextPlaceholder content={ name } length="long" />
                </h4>
                <h6 block="SearchItem" elem="Price">
                    <TextPlaceholder content={ formatPrice(price, currency) } length="short" />
                </h6>
            </figcaption>
        );
    }
    
}

export default ClerkSearchItem;
