/* eslint-disable */
import getStore from 'Util/Store';
import { addPageType, removePageType } from 'Store/PageTypes/PageTypes.action';
import { DISPLAY_MODE_CMS_BLOCK } from 'Route/CategoryPage/CategoryPage.config';

export const IS_HOME_PAGE = 'isHomePage';
export const IS_CMS_PAGE = 'isCmsPage';
export const IS_PRODUCT_PAGE = 'isProductPage';
export const IS_CATEGORY_PAGE = 'isCategoryPage';
export const IS_SEARCH_PAGE = 'isSearchPage';
export const IS_CHECKOUT = 'isCheckout';
export const IS_BLOG_OVERVIEW = 'isBlogOverview';
export const IS_CATEGORY_PAGE_CMS_ONLY = 'isCategoryPageCmsOnly';
export const IS_CART_PAGE = 'isCartPage';

const addClass = (...classNames) => (args, callback, instance) => {
    classNames.forEach(className => {
        document.body.classList.add(className);
        getStore().dispatch(addPageType(className));
    });
    return callback.apply(instance, args);
}

const removeClass = (...classNames) => (args, callback, instance) => {
    classNames.forEach(className => {
        document.body.classList.remove(className);
        getStore().dispatch(removePageType(className));
    });
    return callback.apply(instance, args);
}

const categoryPageDidMount = (args, callback, instance) => {
    const { category: { display_mode = '' } } = instance.props;

    if (display_mode === DISPLAY_MODE_CMS_BLOCK) {
        return addClass(IS_CATEGORY_PAGE, IS_CATEGORY_PAGE_CMS_ONLY)(args, callback, instance);
    }

    return addClass(IS_CATEGORY_PAGE)(args, callback, instance);
}

const categoryPageDidUpdate = (args, callback, instance) => {
    const [ prevProps ] = args;
    const { category: { display_mode = '' } } = instance.props;
    const { category: { display_mode: prevDisplayMode = '' } } = prevProps;

    if (display_mode !== prevDisplayMode) {
        if (display_mode === DISPLAY_MODE_CMS_BLOCK) {
            return addClass(IS_CATEGORY_PAGE_CMS_ONLY)(args, callback, instance);
        } else {
            return removeClass(IS_CATEGORY_PAGE_CMS_ONLY)(args, callback, instance);
        }
    }

    return callback.apply(instance, args);
}

export default {
    'Route/HomePage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_HOME_PAGE),
            'componentWillUnmount': removeClass(IS_HOME_PAGE)
        }
    },
    'Route/CmsPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CMS_PAGE),
            'componentWillUnmount': removeClass(IS_CMS_PAGE)
        }
    },
    'Route/ProductPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_PRODUCT_PAGE),
            'componentWillUnmount': removeClass(IS_PRODUCT_PAGE)
        }
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            'componentDidMount': categoryPageDidMount,
            'componentWillUnmount': removeClass(IS_CATEGORY_PAGE, IS_CATEGORY_PAGE_CMS_ONLY),
            'componentDidUpdate': categoryPageDidUpdate
        }
    },
    'Route/SearchPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_SEARCH_PAGE),
            'componentWillUnmount': removeClass(IS_SEARCH_PAGE)
        }
    },
    'Route/Checkout/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CHECKOUT),
            'componentWillUnmount': removeClass(IS_CHECKOUT)
        }
    },
    'Route/CartPage/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_CART_PAGE),
            'componentWillUnmount': removeClass(IS_CART_PAGE)
        }
    },
    'ScandiPWA/Blog/Component/PostsListing/Container': {
        'member-function': {
            'componentDidMount': addClass(IS_BLOG_OVERVIEW),
            'componentWillUnmount': removeClass(IS_BLOG_OVERVIEW)
        }
    }
}