/* eslint-disable */
import {
    TYPE_PRODUCT
} from 'Route/UrlRewrites/UrlRewrites.config';
import { CONFIGURABLE } from 'Util/Product/Types';


const getTypeSpecificProps = (args, callback, instance) => {
    const {
        urlRewrite: {
            originalSku
        }
    } = instance.props;

    const result = callback.apply(instance, args);

    switch (instance.getType()) {
        case TYPE_PRODUCT:
            const isLoading = instance.getIsLoading();
            if (isLoading) {
                const product = history?.state?.state?.product;

                if (product) {
                    const { type_id } = product;

                    if (type_id === CONFIGURABLE) {
                        return {
                            ...result,
                            originalSKU: originalSku
                        };
                    }
                }

                return result;
            }

            return {
                ...result,
                originalSKU: originalSku
            };
        default:
            return result;
    }
}

export default {
    'Route/UrlRewrites/Container': {
        'member-function': {
            getTypeSpecificProps
        }
    }
}