/* eslint-disable */

// imports the original component from vendor folder
import HoeksMenu from 'HoeksComponent/Menu/Menu.component';
export * from 'HoeksComponent/Menu/Menu.component';
import { getSortedItems } from 'Util/Menu';
import CmsBlock from 'Component/CmsBlock';
import MenuItem from 'Component/MenuItem';

// import new extended styles file
import './Menu.override.style.scss';

export class Menu extends HoeksMenu { 
    /* render missing categories */    
    renderDesktopSubLevelItems(item, mods) {
        const { item_id, item_class, children } = item;
        const { closeMenu, activeMenuItemsStack } = this.props;
        const childrenArray = getSortedItems(Object.values(children));

        const isHideOnDesktop = item_class === 'Menu-ItemFigure_type_hideOnDesktop';
        const itemMods = { ...mods, isHideOnDesktop };

        return (
            <>
                <div class="Menu-ItemContainer">
                <MenuItem
                    activeMenuItemsStack={ activeMenuItemsStack }
                    item={ item }
                    itemMods={ itemMods }
                    closeMenu={ closeMenu }
                    isLink
                    key={ item_id }
                />
                {
                    childrenArray.length ?
                        <div
                            block="Menu"
                            elem="SubLevelItemList"
                        >
                            {childrenArray.map((subItem) => this.renderDesktopSubLevelItems(subItem, itemMods))}
                        </div>
                    :
                        <></>
                }
                </div>
            </>
        );
    }

    renderPromotionCms() {
        return (
            <>
            </>
        );
    }

    renderTopLevel() {
        const { menu } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));

        return (
            <>
                <div block="Menu" elem="MainCategories">
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                    >
                        { childrenArray.map(this.renderFirstLevel) }
                    </ul>
                    { this.renderAdditionalInformation(true) }
                </div>
                { this.renderSubMenuDesktop(children) }
            </>
        );
    }

    renderSubLevelItems = (item) => {
        const {
            handleSubcategoryClick,
            activeMenuItemsStack,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        const {
            item_id,
            children,
            item_class
        } = item;

        const isBanner = item_class === 'Menu-ItemFigure_type_banner';
        const isHideOnDesktop = item_class === 'Menu-ItemFigure_type_hideOnDesktop';
        const mods = {
            isBanner: !!isBanner,
            isHideOnDesktop: !!isHideOnDesktop
        };

        const childrenArray = Object.values(children);
        const subcategoryMods = { type: 'subcategory' };
        const hasChildren = childrenArray.length ? true : false;

        if (childrenArray.length && device.isMobile) {
            return (
                <div
                  key={ item_id }
                  // TODO: split into smaller components
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  role="button"
                  itemMods = { hasChildren }
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      itemMods={ subcategoryMods, hasChildren }
                      onCategoryHover={ onCategoryHover }
                      closeMenu={ closeMenu }
                    />
                    { this.renderSubLevel(item) }
                </div>
            );
        }

        return (
            <div
              block="Menu"
              elem="SubItemWrapper"
              key={ item_id }
              mods={ { mods, hasChildren } }
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  closeMenu={ closeMenu }
                  isLink
                />
                { this.renderDesktopSubLevel(item) }
            </div>
        );
    };

    renderSubMenuDesktopItems = (item) => {
        const { item_id, children } = item;

        if (!Object.keys(children).length) {
            return null;
        }

        const { activeMenuItemsStack, closeMenu } = this.props;
        const isVisible = activeMenuItemsStack.includes(item_id);

        if (!isVisible) {
            return null;
        }
        
        // Check for body class defining site/language
        const whichSite = document.body.classList.contains('picment_dk_store_view') ? 'DK' : 'SE';

        if (whichSite === 'DK') {
            return (
                <div
                  block="Menu"
                  elem="SubCategoriesWrapper"
                  mods={ { isVisible } }
                  key={ item_id }
                >
                    <div
                      block="Menu"
                      elem="SubCategoriesWrapperInner"
                      mods={ { isVisible } }
                    >
                        <div
                          block="Menu"
                          elem="SubCategories"
                        >
                            { this.renderSubLevel(item) }
                        </div>
                        { this.renderAdditionalInformation() }
                    </div>
                    <div
                      block="Menu"
                      elem="Overlay"
                      mods={ { isVisible } }
                      onMouseEnter={ closeMenu }
                    />
                    <CmsBlock identifier="menu_usp" />
                </div>
            );
        } else {
            return (
                <div
                  block="Menu"
                  elem="SubCategoriesWrapper"
                  mods={ { isVisible } }
                  key={ item_id }
                >
                    <div
                      block="Menu"
                      elem="SubCategoriesWrapperInner"
                      mods={ { isVisible } }
                    >
                        <div
                          block="Menu"
                          elem="SubCategories"
                        >
                            { this.renderSubLevel(item) }
                        </div>
                        { this.renderAdditionalInformation() }
                    </div>
                    <div
                      block="Menu"
                      elem="Overlay"
                      mods={ { isVisible } }
                      onMouseEnter={ closeMenu }
                    />
                </div>
            );
        }
        
    };
}

export default Menu;
