/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable */

import { UPDATE_CATEGORIES } from './Categories.action';

export const emptyCategories = {
  items: [],
  count: 0,
};

/** @namespace ScandiPWA/Blog/Store/Categories/Reducer/getInitialState */
export const getInitialState = () => ({
  categories: emptyCategories,
});

/** @namespace ScandiPWA/Blog/Store/Categories/Reducer/categoriesReducer */
export const CategoriesReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case UPDATE_CATEGORIES:
      const {
        categories: { items, total_count: count },
        isLoaded,
      } = action;

      const sortedItems = items.sort(({ position: posOne = 0 }, { position: posTwo = 0 }) => posOne - posTwo);

      return {
        ...state,
        categories: {
          items: sortedItems,
          count,
        },
        isLoaded,
      };
    default:
      return state;
  }
};

export default CategoriesReducer;
